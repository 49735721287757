import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { isEmpty } from 'lodash';
import { RingLoader } from 'react-spinners';

import { context } from '../../context/house/context';
import { getSingleHouse } from '../../redux/actions/singleHouse';
import { clearDocuments, clearFilteredDocuments } from '../../redux/actions/houseDocuments';

import Modal from '../../components/ui/modal/Modal';
import Accordion from '../../components/ui/Accordion';
import Pagination from '../../components/ui/Pagination';
import { Slider } from '../../components/ui/Slider';
import {houseDetailHeader} from '../../data/constant';

const HouseInfo = () => {

    const houseInfoContext = useContext(context);
    const { id, dispatch, closeModal,  
        house, attributes, images,
        documents, filteredDocuments, documentsList, activeDocModal,
        perPage, setPerPage, handlePerPage, 
        currentPage, setCurrentPage, handlePageChange,
        showDoc, handleShowDoc,
        shareDoc, activeShareModal, handleShareDoc } = houseInfoContext;

    useEffect(() => {
        dispatch(getSingleHouse(id));
        setPerPage(5);
        setCurrentPage(1);
    }, []);

    useEffect(() => () => {
        dispatch(clearDocuments());
        dispatch(clearFilteredDocuments());
    }, []);

  return (
    <>
    {!isEmpty(house) ? (
        <main className='max-container m-auto p-container'>
            <Helmet>
                <title>QR-House | House Information</title>
            </Helmet>
            <div className='row m-0 mb-4'>
                <h4 className='col-12 col-sm-6 py-2 my-auto dark-purple bold'>House Information</h4>
            </div>
            <section className="row m-0 overflow-hidden">
                <div className="col-12 col-lg-5 position-relative p-0 p-md-3 h-max-500">
                    {house.attributes && isEmpty(house.attributes.image) ?
                        <img src='/images/house_xxl.png' alt="image-slide" className='w-100 h-100 cover'/>
                    :
                    <> {isEmpty(images) ? <div className='w-100 h-100 d-center'> <RingLoader color='#312782' loading={true} size={60} /> </div>  : <Slider images={images} />} </>}  
                </div>

                <div className="col-12 col-lg-7 my-3 my-md-0 py-2">
                    <div className='row m-0'>
                        <h2 className='col-12 mb-4'>House Information</h2>
                        <div className='col-12 overflow-hidden mb-2'>
                            <span className='olive text-uppercase me-2'>House ID Registration: </span>
                            <span className='dark-blue m-0 word-break'>{attributes.owner[0]}</span>
                        </div>
                        <div className='col-12 overflow-hidden mb-2'>
                            <span className='olive text-uppercase me-2'>Registration Date: </span>
                            {/* <span className='dark-blue m-0'>{format(new Date(attributes.regdate), 'dd/MM/yyyy')}</span> */}
                            <span className='dark-blue m-0'>{attributes.regdate}</span>
                        </div>
                        <div className='col-12 overflow-hidden mb-2'>
                            <span className='olive text-uppercase me-2'>Transaction ID: </span>
                            <span className='dark-blue m-0 word-break'>{house.txh}</span>
                        </div>
                        {/* <div className='col-12 overflow-hidden mb-2'>
                            <span className='olive text-uppercase me-2'>Address: </span>
                            <span className='dark-blue m-0'>{`${attributes.address} ${attributes.geo_zip}, ${attributes.geo_region}, ${attributes.geo_state}`}</span>
                        </div> */}
                        <div className="row m-0">
                            <div className='col-12 location overflow-hidden d-flex align-items-center rounded mb-4 p-0'>
                                <img src="/images/location-map.png" alt="location-icon" className='h-100' style={{width:'47px'}}/>
                                <p className='m-0 px-3' >
                                    <a className='dark-blue text-decoration-none' 
                                        target="_blank"
                                        href={`http://maps.google.com/?q=${attributes.address} ${attributes.geo_zip}, ${attributes.geo_region} ${attributes.geo_state}`}>
                                        {`${attributes.address} ${attributes.geo_zip}, ${attributes.geo_region} ${attributes.geo_state}`}
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row m-0'>
                        <h2 className='col-12 mb-4'>Owner Information</h2>
                        <div className='col-9 overflow-hidden'>
                            <h6 className='olive text-uppercase'>Owner Address</h6>
                            <h6 className='dark-blue m-0 word-break'>{attributes.owner}</h6>
                        </div>
                        <div className='col-3 overflow-hidden d-flex justify-content-end'>
                            <img src="/images/avatar-purple.png" alt="user-avatar" />
                        </div>
                        {/* <div className="col-12 mt-4">
                            <h6 className='olive text-uppercase'>About Owner</h6>
                            <p className='m-0 h-100 overflow-auto pe-2' style={{maxHeight:'190px'}}></p>
                        </div> */}
                    </div>
                </div>
            </section>

            <section className="row m-0 mt-5">
                <div className="col-12 col-lg-3 col-xl-3">
                    <h4 className=' py-2 my-auto dark-purple bold mb-4'>Categories</h4>
                    <div className="py-1 row">
                    <Accordion documents={documents} setCurrentPage={setCurrentPage}/>
                    </div>
                </div>
                    
                <div className="col-12 col-lg-9 col-xl-9 ps-lg-5">
                    <div className='row mb-4'>
                        <h4 className='col-12 col-sm-5 col-md-6 py-2 my-auto dark-purple bold'>Documents</h4>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-12 p-0 overflow-hidden'>
                            <div className='t-head mb-2 p-1'>   
                                <div className='px-2'>
                                    <ul className='house-header'>
                                        {houseDetailHeader.map( (header, index) => {
                                            const item = index === 0 ? 
                                            <li key={index} className='house-header-content px-1' style={{maxWidth:header.width, width:header.width}}>
                                                {header.name}
                                            </li> :
                                            <li key={index} className='house-header-content px-3' style={{width:header.width}}>
                                                {header.name}
                                            </li>
                                            return item;
                                        })}
                                    </ul>
                                </div>

                                <div className='house-list-wrapper p-2'> 


                                    {house.attributes && isEmpty(house.attributes.doc) ?
                                        <></>
                                    :
                                        <> 
                                            {isEmpty(documents) ? 
                                            <div className='w-100 h-100 d-center py-3'> 
                                                <RingLoader color='#312782' loading={true} size={60}/> 
                                            </div>  
                                            : 
                                            <>
                                                {documentsList.map((document, index) => (
                                                    <ul key={index} className='house-list-item'>
                                                        <li className='house-content px-3' style={{maxWidth:'67px', backgroundColor:'#efb994'}}>
                                                        <img src="/images/info.png" alt="QR-house" />
                                                        </li>
                                                        <li className='house-content word-break ps-4 pe-3 olive justify-content-center' style={{width:'180px'}}>
                                                        {/* <span>{document.data.name.replace(/\.[^/.]+$/, "")}</span> */}
                                                        <span>{document.data.name}</span>
                                                        </li>
                                                        <li className='house-content word-break px-3' style={{width:'180px'}}>
                                                        <span>{document.data.description}</span>
                                                        </li>
                                                        <li className='house-content word-break px-3' style={{width:'180px'}}>
                                                        <span>{document.data.type}</span>
                                                        </li>
                                                        <li className='house-content px-3' style={{width:'180px'}}>
                                                        <a  className='round-50' href={document.data.url} target='_blank' download>
                                                            <button className='custom-btn btn-light-purple'>Download</button>
                                                        </a>
                                                        </li> 
                                                        <li className='house-content px-3' style={{width:'90px'}}>
                                                            <img className='pointer' src="/images/info-icon.png" alt="information" onClick={() => handleShowDoc(document)} />
                                                        </li>

                                                        <li className='house-content px-3' style={{width:'90px'}}>
                                                            <i className="fa fa-share-alt fa-lg p-2 pointer" aria-hidden="true" onClick={() => handleShareDoc(document)}></i>
                                                        </li>
                                                    </ul> 
                                                ))}
                                            </>
                                            } 
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>
            </section>

            <section className="row mt-3 flex-column-reverse flex-lg-row m-0">
                <div className="col-12 col-lg-3 col-xl-3">
                    <div className='d-flex align-items-center' style={{maxWidth:'150px'}} >
                        <label className="me-3 text-nowrap">Show</label>
                        <select className="form-select" aria-label="select number of rows" value={perPage} onChange={handlePerPage} >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                        </select>
                    </div> 
                </div>
                <div className='col-12 col-lg-9 col-xl-9 ps-lg-5 d-flex justify-content-center overflow-hidden mb-4 mb-lg-0'>
                    <Pagination totalHouses={filteredDocuments.length} currentPage={currentPage} perPage={perPage} onPageChange={handlePageChange} />
                </div>
            </section>
            <Modal 
                activeDocModal={activeDocModal} document={showDoc} 
                activeShareModal={activeShareModal} shareDoc={shareDoc}
                closeModal={closeModal}
            />
        </main>
    ) : null}
    </>
  )
}
export default HouseInfo;