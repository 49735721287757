import { isEmpty } from 'lodash';
import Select from 'react-select';
import { RingLoader } from 'react-spinners';

const AddDocumentDialog = ({ addDocumentSubmit, deleteDocument, closeModal, documents, setDocuments, uploadDocument, validator, options, selectStyle, btnRef, btnLoading }) => {
    return (
        <div className='customModal'> 
            <div className='h-100 vw-100 overflow-auto'>
                <div className='modal-box'>

                    <div className="row pt-1 pb-3 mb-4 border-bottom-light-gray">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <h4 className='py-2 my-auto dark-purple bold'>Add Document</h4>
                            <i className="fa fa-times fa-lg pointer p-2" aria-hidden="true" onClick={closeModal}></i>
                        </div>
                    </div>

                    <form  onSubmit={addDocumentSubmit}>
                        <div className="row mb-4 border-bottom-light-gray">
                            {/* DOCUMENTS */}
                            <section className="row m-0">   
                                <div className="col-12 p-0">
                                    <div className="row m-0">
                                        <label className="col-6 dark-purple bold fs-16 text-uppercase mb-2 p-0"> Documents </label>
                                        {/* <label className={`col-6 dark-purple bold fs-16 text-uppercase mb-2 ${isEmpty(documents) ? 'd-none' : 'd-block'}`}> File Type </label>                                               */}
                                        {!isEmpty(documents) &&
                                            documents.map((document, index) => (
                                                <section key={index} className='row m-0 p-0 py-2 mb-3'>
                                                    <div className='col-12 col-md-6 p-0 mb-2 pe-md-2'>
                                                        <div className=" custom-file-upload word-break bg-white">
                                                            <span className='w-75 h-100 pe-2 overflow-hidden d-flex align-items-center'>{document.name}</span>
                                                            <div 
                                                                className='custom-btn btn-rose-gold-fill d-center pointer' 
                                                                style={{height:'36px'}}
                                                                onClick={() => deleteDocument(index)}    
                                                            >   Delete
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 ps-md-2 p-0 mb-2'>
                                                        <Select 
                                                            id="file_type" 
                                                            name="file_type" 
                                                            options={options} 
                                                            placeholder="select the file type"
                                                            isClearable  
                                                            styles={selectStyle}
                                                            value={{value: document.type || '' , label: document.type || ''}}
                                                            // defaultValue={{value: document.type || ''}}

                                                            onChange={(e) => {
                                                                const docs = [...documents];
                                                                const selectedDoc = docs[index];
                                                                selectedDoc.type =  e && e.value ? e.value : '';
                                                                setDocuments(docs);
                                                                validator.current.showMessageFor("file_type");
                                                                
                                                            }}
                                                        />

                                                        <div className='input-error-message'>
                                                            {validator.current.message("file_type", document.type, "required")}
                                                        </div>

                                                    </div>
                                                    <div className='col-12 p-0 '>
                                                        <input 
                                                            type="text" 
                                                            name="description" 
                                                            id="description" 
                                                            className="form-input" 
                                                            placeholder="example: Gas certificate"
                                                            // onChange={e => document.description = e.target.value}
                                                            value={document.description}
                                                            onChange={e => {
                                                                const docs = [...documents];
                                                                const selectedDoc = docs[index];
                                                                selectedDoc.description =  e.target.value ? e.target.value : '';
                                                                setDocuments(docs);
                                                            }}
                                                        />
                                                    </div>
                                                </section>
                                            ))
                                        }
                                        <div className="col-12 mb-2 p-0">
                                            <div className="custom-file-upload">
                                                <span>Please Choose the file</span>
                                                <label htmlFor="documents" className='custom-btn btn-orange-outline pointer d-center' style={{height:'36px'}}> Choose file </label>
                                                <input 
                                                    type="file" 
                                                    name="documents" 
                                                    id="documents" 
                                                    className="form-input custom-file-upload" 
                                                    placeholder="No file selected"
                                                    onChange={e => uploadDocument(e)}
                                                />
                                            </div>
                                            <div className='input-error-message'> </div>    
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>  
                        {/* <div className="row pt-1 ">
                            <div className="col-12 d-flex justify-content-end">
                                <button className='custom-btn btn-milky-fill' type='submit' >Add Document</button>
                            </div>
                        </div> */}

                        <div className="row pt-1">
                            <div className="col-12 d-flex justify-content-end">
                                <button ref={btnRef} className='custom-btn btn-milky-fill' type='submit'>
                                    {btnLoading ? 
                                        <div className='w-100 h-100 d-center'> 
                                            <RingLoader color='#312782' loading={btnLoading} size={25}/> 
                                        </div>  
                                    : 'Add Document'}
                                </button>
                            </div>
                        </div>

                    </form> 

                </div>
            </div>
        </div>
    )
}

export default AddDocumentDialog;