import { createContext } from "react";

export const context = createContext({
    showFilter: null,
    activeHomeModal: null,
    perPage: null,
    currentPage: null,
    housesList: [],
    filteredHouses: [],
    houses:[],
    user: {},

    setShowFilter: () => {},
    setActiveHomeModal: () => {},
    setPerPage: () => {},
    setCurrentPage: () => {},
    handlePerPage: () => {},
    handlePageChange: () => {},
    closeHomeModal: () => {},








    activeAddDocModal: null,
    setActiveAddDocModal: ()=> {},
    activeAddImgModal: null,
    setActiveAddImgModal: ()=> {},
    activeDocModal: null,
    // setActiveDocModal: () => {},
    handleCloseModal: () => {},
    closeDocModal: () => {},
    closeImgModal: () => {},
    handleShowDoc: () => {},
    showDoc: {},
    attributes: {},

    labelRef: null,
    handleDownloadLabel: () => {},
    flip: null,
    setFlip: () => {},
    qr: null, 
    setQr: () => {},
    documentsList: [],
    houseImages: [],
    





});