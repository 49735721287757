import { getHouseDocument, getHouseImages, getHouseInformation, getHouseQRCode } from "../../services/houseService";

export const getSingleHouse = quid => {
    return async dispatch => {
        const {data} = await getHouseInformation(quid);
        await dispatch({type: "GET_HOUSE", payload: data});
        if(data.attributes.doc) {
            const {doc} = data.attributes;
            // doc.map(async document => {
            //     const {data} = await getHouseDocument(document);
            //     await dispatch({type: "GET_DOCUMENTS", payload: data})
            //     await dispatch({type: "GET_DOCUMENTS_FILTER", payload: data})
            // });

            
            
            // const promise = [];
            // doc.map(async document => {
            //     const {data} = await getHouseDocument(document);
            //     promise.push(data);
            // });
            // Promise.all(promise).then(result => {
            //     dispatch({type: "GET_DOCUMENTS", payload: result});
            //     dispatch({type: "GET_DOCUMENTS_FILTER", payload: result});
            // });


            const promise = [];
            doc.map(document => promise.push(getHouseDocument(document)));
            Promise.all(promise).then(result => {
                const documents =[];
                result.map(res => documents.push(res.data));
                dispatch({type: "GET_DOCUMENTS", payload: documents});
                dispatch({type: "GET_DOCUMENTS_FILTER", payload: documents});
            });
        }

        if(data.attributes.image) {
            const {image} = data.attributes;
            // image.map(async img => {
            //     const {data} = await getHouseImages(img);
            //     await dispatch({type: "GET_IMAGES", payload: data})
            // });

            // const promise = [];
            // image.map(async img => {
            //     const {data} = await getHouseImages(img);
            //     promise.push(data);
            // });
            // Promise.all(promise).then(result => {
            //     dispatch({type: "GET_IMAGES", payload: result});
            // });

            const promise = [];
            image.map(img => promise.push(getHouseImages(img)));
            Promise.all(promise).then(result => {
                const images =[];
                result.map(res => images.push(res.data));
                dispatch({type: "GET_IMAGES", payload: images});
                console.log(images)
            });

        }
    }
}

export const clearHouse = () => {
    return async dispatch => {
        await dispatch({type:"CLEAR_HOUSE", payload: {}});
    }
}