import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { decodeToken } from 'react-jwt';
import SimpleReactValidator from 'simple-react-validator';

import { addUser } from '../../redux/actions/user';
import { getUserInfo } from '../../redux/actions/userInfo';
import { loginUser, registerUser } from '../../services/userService';
import { errorMessage, successMessage } from '../../utils/message';
import { context } from './context';

const UserContext = ({children}) => {

    const roles = useSelector(state => state.roles);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams] = useSearchParams();

    const [referral, setReferral] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [role, setRole] = useState("");
    const [policy, setPolicy] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [isVisibleConfirm, setVisibleConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [, forceUpdate] = useState();

    const loginValidator = useRef(new SimpleReactValidator({
        element: message => <div style={{color:'#ef5350'}}>{message}</div>
    }));

    const registerValidator = useRef(new SimpleReactValidator({
        element: message => <div style={{color:'#ef5350'}}>{message}</div>
    }));

    const rolesOption=[];
    roles.map(role => rolesOption.push({value: role.name, label: role.name}));

    const resetStates = () => {
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setRole("");
        setPolicy(false);
    }

    const handleLogin = async event => {
        event.preventDefault();
        const user = {email, password};
        try {
            if(loginValidator.current.allValid()) {
                setLoading(true);
                const {data, status} = await loginUser(user);
                if(status === 200) {
                    successMessage("Login has been successful.");
                    setLoading(false);
                    localStorage.setItem("token", data.token);
                    dispatch(addUser(decodeToken(data.token)));
                    dispatch(getUserInfo(data.user_quid));
                    navigate("/houses", { replace: true });
                    resetStates();
                }
            } else {
                registerValidator.current.showMessages();
                forceUpdate(1);
            }
        } catch(ex){
            const {status} = ex.response;
            if (status === 403) {
                errorMessage("User not verified. Please complete the email registration.");
                setLoading(false);
            }
            else {
                errorMessage("Login has been failed.");
                setLoading(false);
            }
        }
    }

    const handleRegister = async event => {
        event.preventDefault();
        const user = { email, pass: password, role, referral };
        try {
            if(registerValidator.current.allValid() && password === confirmPassword) {
                setLoading(true);
                const { status } = await registerUser(user);
                if(status === 200) {
                    successMessage("Please check your email and complete your registration.");
                    setLoading(false);
                    navigate("/register/confirm", { replace: true });
                    resetStates();
                }
            } else {
                registerValidator.current.showMessages();
                forceUpdate(1);
            }
        } catch(ex) {
            const {status} = ex.response;
            if (status === 409) {
                errorMessage("The Email is already registered.");
                setLoading(false);
            }
            else {
                errorMessage("Registration has been failed.");
                setLoading(false);
            }
        }  
    }
    
    useEffect(() => {
        if (location.pathname === '/register' && searchParams.get('referral')) {
            setReferral(searchParams.get('referral'));
        }
    }, [searchParams]);

    return (
        <context.Provider value={{
            email, setEmail, password, setPassword, confirmPassword, setConfirmPassword, role, setRole, policy, setPolicy, isVisible, setVisible,
            isVisibleConfirm, setVisibleConfirm, loading, handleLogin, handleRegister, rolesOption, loginValidator, registerValidator, referral
        }}>
            {children}
        </context.Provider>
    )
}

export default UserContext;