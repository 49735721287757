export const houseImagesReducer = (state = [], action) => {
    switch(action.type) {
        case "GET_IMAGES":
            return [...action.payload];
        case "ADD_IMAGES":
            return [...action.payload];
        case "CLEAR_IMAGES":
            return[...action.payload];
        default:
            return state;
    }
}