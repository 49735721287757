import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, FreeMode, Thumbs } from 'swiper';

const ImageDialog = ({closeModal, houseImages, activeImage, thumbsSwiper, setThumbsSwiper}) => {
    return (
        <div className='customModal' style={{backgroundColor:'#222222ee'}}> 
            <div className='h-100 vw-100 overflow-auto position-relative'>
                <div className="row m-0 pt-3 pe-3">
                    <div className="col-12 text-end white">
                        <i className="fa fa-times fa-2x pointer p-2" aria-hidden="true" onClick={closeModal}></i>
                    </div>
                </div>
                <div className='w-100 mx-auto' style={{backgroundColor:'#333',maxWidth:'1000px',  marginTop:'50px', marginBottom:'50px', height:'700px'}}>
                    <Swiper
                        loop={houseImages.length > 1 ? true : false}
                        spaceBetween={0}
                        initialSlide={activeImage}
                        slidesPerView={1}
                        navigation={true}
                        freeMode={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[ Navigation, Thumbs, Pagination, FreeMode]}
                        className='w-100 rounded '
                        style={{maxWidth:'1000px', height:'570px'}}   
                    >
                        { houseImages.map((image, index) => (
                            <SwiperSlide key={index} className='w-100 d-center my-auto rounded' style={{  height:'100%'}}> 
                                <img src={image.data.url ? image.data.url : '/images/house_xxl.png'} alt="image-slide" className='rounded' style={{maxHeight:'100%', maxWidth:'100%'}} /> 
                            </SwiperSlide> 
                        ))}
                    </Swiper>
                    {houseImages.length > 1 ? 
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            spaceBetween={8}
                            initialSlide={0}
                            slidesPerView={4}
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[Navigation, FreeMode, Thumbs]}
                            className='w-100 '
                            style={{  height:'100px', marginTop:'30px'}}
                        >
                        {houseImages.map((image, index) => (
                            <SwiperSlide key={index} className='' style={{ width:'100%', maxWidth:'100px'}}>
                                <div className=' rounded overflow-hidden w-100 h-100' style={{width:'100px', backgroundColor:'#ffffffaa'}}>
                                    <img src={image.data.thumbnail_url} alt="image-slide" className='w-100 h-100 cover pointer '/>
                                </div>
                            </SwiperSlide>
                        ))}
                        </Swiper>
                        : 
                        null
                    }
                </div>

            </div>
        </div>
    )
}

export default ImageDialog