import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import Button from '../../components/ui/Button';
import { getPaymentList } from '../../services/houseService'

const Invoices = () => {
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getPaymentList().then(res => {
      // console.log(res);
      setPaymentList(res.data);
      setLoading(false);

    });
  }, [])
  return (
    <>
      <h4 className='dark-purple bold mb-5'>Invoices</h4> 
      <div className="">

        {loading ? 
          <div className='w-100 h-100 d-center py-3'> 
            <RingLoader color='#312782' loading={true} size={60}/> 
          </div>  
          :
          <table className='w-100'> 
            <thead>
            <tr className='text-center'>
              <th>Date</th>
              <th>Product Name</th>
              <th>Product Price</th>
              <th>Status</th>
              <th>House</th>
              <th>Invoice</th>
            </tr>
            </thead>
            <tbody className='text-center'>
              {paymentList.map(paymentItem => (
                <tr key={paymentItem.id}>
                  <td>{format(new Date(paymentItem.datetime), 'dd/MM/yyyy')}</td>
                  <td>{paymentItem.product.name}</td>
                  <td>{paymentItem.product.price} €</td>
                  <td>{paymentItem.status}</td>
                  {paymentItem.status === 'completed' ? <td>
                    <Link to={isEmpty(paymentItem.house_quid) ? `/houses/add-house/${paymentItem.id}` : `/house-details/${paymentItem.house_quid}`} >
                      <Button text={isEmpty(paymentItem.house_quid) ? 'Add House' : 'House'} bg={isEmpty(paymentItem.house_quid) ? 'milky-fill' : 'light-purple'} />
                    </Link>
                  </td>
                  :
                    <td>
                      {/* <Link to='/houses/payment' >
                        <Button text='Payment' bg='midnight-fill'/>
                      </Link> */}
                    </td>
                  }
                  <td>
                    {paymentItem && paymentItem.invoice_url && <a href={paymentItem.invoice_url} className='d-block'><i className="fa fa-download fa-2x dark-purple"></i></a> }

                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
      </div>
    </>
  )
}

export default Invoices