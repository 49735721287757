import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer, Slide } from 'react-toastify';
import Main from './Main';

const App = () => {
  return (
    <HelmetProvider>
      <Helmet> <title>QR-House</title> </Helmet>
      <Router>
        <Main/>
        <ToastContainer transition={Slide} />
      </Router>
    </HelmetProvider>
  );
}
 
export default App;
