import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.crossDomain = true;

axios.interceptors.request.use(    
    config => {
        const token = localStorage.getItem('token')
        if (token) config.headers.Authorization = `Bearer ${token}`
        return config;
    }, 
    error => Promise.reject(error)
);

const httpMethods = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
}
export default httpMethods;