import id from 'date-fns/esm/locale/id/index.js';
import React, { useRef } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { changePassword } from '../../services/userService';
import { errorMessage, successMessage } from '../../utils/message';

import RingLoader from "react-spinners/RingLoader";
import { useEffect } from 'react';

const ChangePassword = () => {

    const userInfo = useSelector(state => state.userInfo);

    const [isModify, setModify] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [isNewVisible, setNewVisible] = useState(false);
    const [isConfirmVisible, setConfirmVisible] = useState(false);
    // const [username, setUsername] = useState('');

    const [email, setEmail] = useState(userInfo.email);
    const [password, setPassword] = useState('********');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [, forceUpdate] = useState();

    const [loading, setLoading] = useState(true)



    const resetStates = () => {
        // setPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }


    const changePassValidator = useRef(new SimpleReactValidator({
        element: message => <div style={{color:'#ef5350'}}>{message}</div>
    }));

    const validator = changePassValidator;




    const handleChangePassword = async event => {
        event.preventDefault();        
        const user_change_password = { email, old_pass: password, new_pass: newPassword, confirm_pass: confirmPassword};
        try {

            if(changePassValidator.current.allValid() && newPassword === confirmPassword) {
                // console.log({user_change_password});
                const { data, status } = await changePassword(user_change_password);
                
                setTimeout(() => {
                    // console.log(status)
                }, 2000);

                if(status === 200) {
                    // successMessage("Registration has been successfully done");
                    successMessage("The Password is changed successfully");
                    setPassword('********');
                    setVisible(false); 
                    setModify(false);
                    // setLoading(false);
                    // navigate("/login", { replace: true });
                    // console.log(data);
                    resetStates();
                }
            } else {
                changePassValidator.current.showMessages();
                forceUpdate(1);
            }
        } catch(ex) {
            // if(ex.response.status === 401) errorMessage(ex.response.data.detail);
            if(ex.response.status === 401) errorMessage("Old password is wrong");
            else {
                errorMessage("Error : Change Password has been failed");
                // console.log(ex);
            }


        }  
    }

    
    useEffect(() => {
        setEmail(userInfo.email);
    }, [userInfo.email]);

  return (
    <>
        <h4 className='dark-purple bold mb-5'>Change Password</h4> 

        {false ?
            <div className="border">
                <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                    <RingLoader color='#312782' loading={loading} size={80} />
                </div>
            </div>
            :

            <>
                <form onSubmit={handleChangePassword}>
                    <div className="d-flex flex-wrap">
                        {/* EMAIL */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="email" className='d-block mb-2'>Email</label>
                            <input 
                                // disabled={!isModify} 
                                disabled={true} 
                                type="email" 
                                className={`form-input ${!isModify && 'bg-light-skyblue'}`} 
                                id='email' 
                                name='email'
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>

                        {/* PASSWORD */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="password" className='d-block mb-2'>{isModify ? 'Old Password' : 'Password'}</label>
                            <div className="position-relative">
                                <input 
                                    disabled={!isModify} 
                                    type={!isVisible ? "password" : "text"}
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='password' 
                                    name='password'
                                    value={password}
                                    onChange={e => {
                                        setPassword(e.target.value);
                                        validator.current.showMessageFor("password");
                                    }}
                                />
                                {isModify && 
                                    <span className="show-password" onClick={() => setVisible(!isVisible)}>
                                        {!isVisible ? <i className="fa fa-eye dark" aria-hidden="true"></i> : <i className="fa fa-eye-slash dark" aria-hidden="true"></i> }
                                    </span>
                                }
                            </div>
                            <div className='input-error-message'>
                                {validator.current.message("password", password, "required|min:3")}
                            </div>
                        </div> 

                        {/* NEW PASSWORD AND CONFIRMATION */}
                        {isModify && 
                            <>    
                                <div className="mb-4 me-sm-5 w-100 w-max-350">
                                    <label htmlFor="newPassword" className='d-block mb-2'>New Password</label>
                                    <div className="position-relative">
                                        <input 
                                            disabled={!isModify} 
                                            type={!isNewVisible ? "password" : "text"}
                                            className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                            id='newPassword' 
                                            name='newPassword'
                                            value={newPassword}
                                            onChange={e => {
                                                setNewPassword(e.target.value);
                                                validator.current.showMessageFor("newPassword");
                                            }}
                                        />
                                        {isModify && 
                                            <span className="show-password" onClick={() => setNewVisible(!isNewVisible)}>
                                                {!isNewVisible ? <i className="fa fa-eye dark" aria-hidden="true"></i> : <i className="fa fa-eye-slash dark" aria-hidden="true"></i> }
                                            </span>
                                        }
                                    </div>
                                    <div className='input-error-message'>
                                        {validator.current.message("newPassword", newPassword, "required|min:3")}
                                    </div>
                                </div>



                                <div className="mb-4 me-sm-5 w-100 w-max-350">
                                    <label htmlFor="confirmPassword" className='d-block mb-2'>Repeat New Password</label>
                                    <div className="position-relative">
                                        <input 
                                            disabled={!isModify} 
                                            type={!isConfirmVisible ? "password" : "text"}
                                            className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                            id='confirmPassword' 
                                            name='confirmPassword'
                                            value={confirmPassword}
                                            onChange={e => {
                                                setConfirmPassword(e.target.value);
                                                validator.current.showMessageFor("confirmPassword");
                                            }}
                                        />
                                        {isModify && 
                                            <span className="show-password" onClick={() => setConfirmVisible(!isConfirmVisible)}>
                                                {!isConfirmVisible ? <i className="fa fa-eye dark" aria-hidden="true"></i> : <i className="fa fa-eye-slash dark" aria-hidden="true"></i> }
                                            </span>
                                        }
                                    </div>
                                    <div className='input-error-message'>
                                        {validator.current.message("confirmPassword", confirmPassword, "required")}
                                        {newPassword === confirmPassword ? null :
                                            <div style={{color:'#ef5350'}}>Confirm password is not matched</div>
                                        }
                                    </div>
                                </div>  
                            </>   
                        }
                    </div>
                    <div className="">
                        {isModify &&
                        <>
                            <button className='custom-btn btn-light-purple me-3 mt-3' type='submit'>Save</button>
                            <span className='custom-btn me-3 mt-3 required-color pointer' 
                                onClick={() => { 
                                    setModify(false); 
                                    setVisible(false); 
                                    setPassword('********');
                                    setNewPassword('');
                                    setConfirmPassword('');
                                }} >Cancel</span>
                        </>}
                    </div>
                </form>
                <div>
                    {!isModify &&
                        <>
                            <button className='custom-btn btn-midnight-outline me-3 mt-3' 
                                onClick={() => {
                                    setModify(true);
                                    setPassword('');
                                    
                                }}>Modify</button>
                            {/* <Link to="/logout" className='text-decoration-none'> <button className='custom-btn btn-milky-fill mt-3'>Logout</button> </Link> */}
                        </>
                    }
                </div> 
            </>
        }
    </>
  )
}

export default ChangePassword;