// import React from 'react';
// import { Link } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';

// import Footer from '../../components/common/Footer';
// import Header from '../../components/common/Header';
// import Button from '../../components/ui/Button';


import { Navigate, useNavigate } from 'react-router';
import {Link} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Helmet } from 'react-helmet-async';
import { isEmpty } from 'lodash';

import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';

const CancelPayment = () => {  
    
    const user = useSelector(state => state.user);

    // const navigate = useNavigate();
    
    // if(isEmpty(user)) return navigate("/login");
    if(isEmpty(user)) return <Navigate to="/login" />;

    return (
        <div className='main-layout'>
            <Helmet> <title>QR-House | Cancel Payment</title> </Helmet>
            <Header/>
            <main className='mainLayout-main d-center'>

                    <div className='max-container text-center p-2 p-md-5' >    
                        <img src='/images/payment-cancelled.png' alt="alert-image" className='w-100' style={{maxWidth:'344px'}} />
                        <h3 className='mt-5 purple bold text-uppercase'>CANCELLED</h3>
                        <p className='dark-purple'>Payment has been cancelled</p>
                        <Link to='/houses' className="form-link"> <button className='custom-btn btn-milky-fill'>Next</button> </Link>
                    </div>

            </main>
            <Footer/>
        </div> 
    )
}

export default CancelPayment;