import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { decodeToken, isExpired } from "react-jwt";

import { addUser, clearUser } from './redux/actions/user';
import { clearUserInfo, getUserInfo } from './redux/actions/userInfo';
import { clearHouses } from './redux/actions/houses';
import { clearHouse } from './redux/actions/singleHouse';
import { clearDocuments } from './redux/actions/houseDocuments';

import FormLayout from './layouts/FormLayout';
import MainLayout from './layouts/MainLayout';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Logout from './pages/Login/Logout';
import Register from './pages/Register/Register';
import Houses from './pages/QRHouse/Houses';
import HouseDetails from './pages/QRHouse/HouseDetails';
import HouseInfo from './pages/QRHouse/HouseInfo';
import Policy from './pages/Policy/Policy';
import Terms from './pages/Policy/Terms';
import Page404 from './pages/Status/Page404';
import UserContext from './context/user/UserContext';
import HouseContext from './context/house/HouseContext';
import Dashboard from './pages/Dashboard/Dashboard';
import Success from './pages/Status/Success';
import Profile from './pages/Dashboard/Profile';
import Invoices from './pages/Dashboard/Invoices';
import CancelPayment from './pages/Status/CancelPayment';
import ChangePassword from './pages/Dashboard/ChangePassword';
import RegisterConfirmation from './pages/Register/RegisterConfirmation';
import Tutorial from './pages/Dashboard/Tutorial';

const Main = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token) {
            const userDecodedToken = decodeToken(token);
            const isUserTokenExpired = isExpired(token);
            
            if(isUserTokenExpired === false) {
                dispatch(addUser(userDecodedToken));
                dispatch(getUserInfo(userDecodedToken.user_quid));
            } else {
                localStorage.removeItem("token");
                dispatch(clearUser());
                dispatch(clearUserInfo());
                dispatch(clearHouses());
                dispatch(clearHouse());
                dispatch(clearDocuments());
            }
        }
    }, [dispatch]);

    return ( 
        <Routes>
            <Route element={<MainLayout/>}>
                <Route path='houses/add-house/:id' element={<HouseContext> <Houses/> </HouseContext>} />
                <Route path='houses/payment' element={<HouseContext> <Houses/> </HouseContext>} />
                <Route path='houses' element={<HouseContext> <Houses/> </HouseContext>} />
                <Route path='house-details/:id' element={<HouseContext> <HouseDetails/> </HouseContext>} />
                <Route path='house-information/:id' element={<HouseContext> <HouseInfo/> </HouseContext>} />
                <Route path="dashboard" element={<Dashboard/>}> 
                    <Route path="" element={<Profile/>} />
                    <Route path="user-profile" element={<Profile/>} />
                    <Route path="invoices" element={<Invoices/>} />
                    <Route path="tutorial" element={<Tutorial/>} />
                    <Route path="change-password" element={<ChangePassword/>} />
                </Route>
            </Route>

            <Route element={<FormLayout/>}>
                <Route path='login' element={<UserContext> <Login/> </UserContext>} />
                <Route path='register' element={<UserContext> <Register/> </UserContext>} />
                <Route path='register/confirm' element={<UserContext> <RegisterConfirmation/> </UserContext>} />
            </Route>

            <Route path='logout' element={<Logout/>} />
            <Route path="privacy-policy" element={<Policy/>} />
            <Route path="terms-conditions" element={<Terms/>} />
            <Route path="success" element={<Success/>} />
            <Route path="cancelled" element={<CancelPayment/>} />
            <Route path="*" element={<Page404/>} />
            <Route path="/" exact element={<Home/>} />
        </Routes>
    );
}
 
export default Main;