import React from 'react'

const Button = ({text, bg}) => {
    return (
        <>
            {bg==='milky-fill' && <button className={`custom-btn btn-${bg}`}> {text} </button>}
            {bg==='midnight-outline' && <button className={`custom-btn btn-${bg}`}> {text} </button>}
            {bg==='midnight-fill' && <button className={`custom-btn btn-${bg}`}> {text} </button>}
            {bg==='light-purple' && <button className={`custom-btn btn-${bg}`}> {text} </button>}  
        </>
    )
}

export default Button