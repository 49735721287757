import { useState } from 'react';
import { isEmpty } from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, FreeMode, Thumbs } from 'swiper';

import Modal from './modal/Modal';

export const Slider = ({images}) => {

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [activeSliderModal,  setActiveSliderModal] = useState(false);
  const [activeImage, setActiveImage] = useState('');
  
  const closeModal = () => {
    setActiveSliderModal(false);
    setActiveImage('');
  }
  const handleOpenSlider = (id) => {
    setActiveSliderModal(true);
    // const filteredImage = images.filter((image, index) => index === id);
    setActiveImage(id);
  }

  return (
    <>
      <Swiper    
        loop={images.length > 1 ? true : false}
        spaceBetween={0}
        initialSlide={0}
        slidesPerView={1}
        navigation={images.length > 1 ? true : false}
        freeMode={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[ Navigation, Thumbs, Pagination, FreeMode]}
        pagination={{ clickable: false, type: "fraction" }}
        className={images.length > 1 ? 'h-75' : 'h-100' }
      >
        {isEmpty(images) ? 
          <SwiperSlide> 
            <img src='/images/house_xxl.png' alt="image-slide" className='w-100 h-100 cover'/> 
          </SwiperSlide>
          :
          images.map((image, index) => (
            <SwiperSlide key={index}> 
              <img onClick={() => handleOpenSlider(index)} src={image.data.url ? image.data.url : '/images/house_xxl.png'} alt="image-slide" className='w-100 h-100 cover pointer'/>
            </SwiperSlide> 
          ))}
      </Swiper>
      {images.length > 1 ? 
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={images.length > 4 ? true : false}
          spaceBetween={8}
          initialSlide={0}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[Navigation, FreeMode, Thumbs]}
          className={`h-25 pt-2`}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index} className='border-light-gray'>
              <img src={image.data.thumbnail_url ? image.data.thumbnail_url : '/images/house_xxl.png'} alt="image-slide" className='w-100 h-100 cover pointer'/>
            </SwiperSlide>
          ))}
        </Swiper>  : null
      }
      {activeSliderModal && <Modal activeSliderModal= {activeSliderModal} activeImage={activeImage}  closeModal={closeModal}/>}
    </>
  );
};
