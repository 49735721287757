import { useContext } from 'react';
import {Link, Navigate} from 'react-router-dom';

import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet-async';
import RingLoader from 'react-spinners/RingLoader';

import { context } from '../../context/user/context';

const Login = () => {    

    const loginContext = useContext(context);
    const { email,  setEmail, password, setPassword, loading, isVisible, setVisible, loginValidator, handleLogin } = loginContext;

    const validator = loginValidator;

    const token = localStorage.getItem('token');
    if(!isEmpty(token)) return <Navigate to='/houses' replace /> ;

    return (
        <>
            <Helmet> <title>QR-House | Login</title> </Helmet>
            <form className='form' onSubmit={handleLogin}>
                {/* EMAIL */}
                <label htmlFor='email' className='form-label'>Email</label>
                <input 
                    type='email' 
                    name='email' 
                    id='email' 
                    className='form-input' 
                    placeholder='yourmail@mail.com' 
                    value={email}
                    onChange={e => {
                        setEmail(e.target.value);
                        validator.current.showMessageFor('email');
                    }}
                />
                <div className='input-error-message'>
                    {validator.current.message('email', email, 'required|email')}
                </div>

                {/* PASSWORD */}
                <label htmlFor='password' className='form-label'>Password</label>
                <div className='position-relative'>
                    <input 
                        type={!isVisible ? 'password' : 'text'} 
                        name='password' 
                        id='password' 
                        className='form-input' 
                        placeholder='* * * * * * * * * * *'
                        value={password}
                        onChange={e => {
                            setPassword(e.target.value);
                            validator.current.showMessageFor('password');
                        }}
                    />
                    <span className='show-password' onClick={() => setVisible(!isVisible)}>
                        {!isVisible ? <i className='fa fa-eye dark' aria-hidden='true'></i> : <i className='fa fa-eye-slash dark' aria-hidden='true'></i> }
                    </span>
                </div>
                <div className='input-error-message'>
                    {validator.current.message('password', password, 'required')}
                </div>

                {/* FORGET PASSWORD */}
                <div className='forget-text'>Did you forget your password?{' '}
                    <a href='https://testapi.qr-house.com/resetpasswordrequest/' className='form-link'>Click here</a> 
                </div>

                {/* SUBMIT BUTTON */}
                <button type='submit' className='form-btn' disabled={loading}>
                    {loading ?
                    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                        <RingLoader color='#312782' loading={loading} size={30} />
                    </div>: <>Sign in</>}
                </button>
            </form>
        </>
    );
}
 
export default Login;