export const tableHeader = [
    {
        name: 'IMG',
        width: '67px'
    },
    {
        name: 'HOUSE ID',
        width: '180px'
    },
    {
        name: 'REGISTRATION DATE',
        width: '200px'
    },
    {
        name: 'COUNTRY',
        width: '120px'
    },
    {
        name: 'REGION',
        width: '100px'
    },
    {
        name: 'ZIP',
        width: '80px'
    },
    {
        name: 'ADDRESS',
        width: '170px'
    },
    {
        name: 'OWNER',
        width: '320px'
    },
]
export const houseDetailHeader = [
    {
        name: 'IMG',
        width: '67px'
    },
    {
        name: 'NAME',
        width: '180px'
    },
    {
        name: 'DESCRIPTION',
        width: '180px'

    },
    {
        name: 'TYPE',
        width: '180px'
    },
    {
        name: 'DOWNLOAD',
        width: '180px'
    },
    {
        name: 'DETAILS',
        width: '90px'
    },
    {
        name: 'SHARE',
        width: '90px'
    },
]
export const accordionList = [
    {
        id: 1,
        label: 'Floor plans',
        imgURL: '/images/icons/accordion/plant_icon.png',
        checked: false,
        sub: []
    },
    {
        id: 2,
        label: 'Provenance deeds',
        imgURL: '/images/icons/accordion/act_icon.png',
        checked: false,
        sub: []
    },
    {
        id: 3,
        label: 'Mortgages and Financing',
        imgURL: '/images/icons/accordion/mortgages_icon.png',
        checked: false,
        sub: []
    },
    {
        id: 4,
        label: 'Changes to the property with respect to purchase',
        // label: 'Modifiche all’immobile',
        imgURL: '/images/icons/accordion/modify_icon.png',
        checked: false,
        sub: []
    },
    {
        id: 5,
        label: 'Cadastral identification',
        imgURL: '/images/icons/accordion/land_registry_icon.png',
        checked: false,
        sub: []
    },
    
    {
        id: 6,
        label: 'Facilities',
        imgURL: '/images/icons/accordion/impianti_icon.png',
        checked: false,
        sub: [
            {
                id: 61,
                label: 'Electrical',
                imgURL: '/images/icons/accordion/elettric_icon.png',
                checked: false,
            },
            {
                id: 62,
                label: 'Heating',
                imgURL: '/images/icons/accordion/heating_icon.png',
                checked: false,
            },
            {
                id: 63,
                label: 'Cooling',
                imgURL: '/images/icons/accordion/cooling_icon.png',
                checked: false,
            },
            {
                id: 64,
                label: 'Plumber',
                imgURL: '/images/icons/accordion/hydraulic_icon.png',
                checked: false,
            },
            {
                id: 65,
                label: 'Swimming Pool',
                imgURL: '/images/icons/accordion/pool_icon.png',
                checked: false,
            },
            {
                id: 66,
                label: 'Others',
                imgURL: '/images/icons/accordion/other_icon.png',
                checked: false,
            },
        ]
    },
    {
        id: 7,
        label: 'Other deeds',
        imgURL: '/images/icons/accordion/other_actS_icon.png',
        checked: false,
        sub: []
    }, 
]