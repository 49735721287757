import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { filterAllDocuments } from '../../redux/actions/houseDocuments';
import { documentFilter } from '../../utils/filter';
import {accordionList} from '../../data/constant';
import { Skeleton } from '@mui/material';

const Accordion = ({documents, setCurrentPage, loading}) => {
    const dispatch = useDispatch();

    const [showSubItem, setShowSubItem] = useState([]);
    const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
    const [isChecked, setChecked] = useState([]);

    const handleDropDown = (index) => { 
        let activeItem = [...showSubItem]
        if (activeItem.includes(index)) {
          activeItem = activeItem.filter(item => item !== index)
        } else {
          activeItem.push(index)
        }
        setShowSubItem(activeItem);
    }
    const handleCheckboxChange = (data) => {
        // console.log(data)
        let checkedItem = [...checkedCheckboxes];
        const label = data.label;
        
        if (checkedItem.includes(label)) {
            checkedItem = checkedItem.filter(item => item !== label)
            data.checked = false;
          } else {
            checkedItem.push(label);
            data.checked = true;
          }
          setCheckedCheckboxes(checkedItem);
        //   console.log(checkedItem);

        //FILTER
        // console.log(documents[1].data.mimetype);
        const filterResult = documentFilter(documents, checkedItem); 
        setCurrentPage(1);
        dispatch(filterAllDocuments(filterResult));        
    }


    // const [loadSkeleton, setLoadSkeleton] = useState(true);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoadSkeleton(false)
    //       }, 4000);
    // }, []);

    return (
        <div className='col-12'>
            <ul className='list-unstyled d-none d-lg-block'>

                { loading ? 
                    <>
                        <Skeleton variant='rectangle' sx={{ height:'67px'}} className='acc-item-header'/>
                        <Skeleton variant='rectangle' sx={{ height:'67px'}} className='acc-item-header'/>
                        <Skeleton variant='rectangle' sx={{ height:'67px'}} className='acc-item-header'/>
                        <Skeleton variant='rectangle' sx={{ height:'67px'}} className='acc-item-header'/>
                        <Skeleton variant='rectangle' sx={{ height:'67px'}} className='acc-item-header'/>
                        <Skeleton variant='rectangle' sx={{ height:'67px'}} className='acc-item-header'/>
                        <Skeleton variant='rectangle' sx={{ height:'67px'}} className='acc-item-header'/>
                    </>
                :
                    <>
                        {accordionList.map((item) => {
                            const accordionItem = isEmpty(item.sub) ? 
                                <li key={item.id} className='acc-item-header'>
                                    <label htmlFor={item.id} className='dark-blue p-3 w-100 pointer d-flex align-items-center'>
                                        {item.imgURL !== '' ? <img src={item.imgURL} alt={item.label} className='me-2 ' style={{width:'33px', height:'33px'}}/> : <div className='me-2' style={{width:'25px', height:'25px'}}></div>}
                                        <span>{item.label}</span>
                                    </label>
                                    <input 
                                        type="checkbox" 
                                        id={item.id} 
                                        name={item.label} 
                                        className='me-3 checkbox-milky' 
                                        value={item.label} 
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                </li>
                            : 
                                <li key={item.id} className='acc-item'>
                                    <div className='acc-item-header' onClick={() => handleDropDown(item.id)}>
                                        <label htmlFor={item.id} className='dark-blue p-3 w-100 pointer'>
                                            {item.imgURL !== '' ? <img src={item.imgURL} alt={item.label} className='me-2 ' style={{width:'33px', height:'33px'}}/> : <div className='me-2' style={{width:'33px', height:'33px'}}></div>}
                                            {item.label}
                                        </label>
                                        {showSubItem.includes(item.id) ? 
                                        <i className="fa fa-chevron-up me-3 fs-10" aria-hidden="true"></i> : 
                                        <i className="fa fa-chevron-down me-3 fs-10" aria-hidden="true"></i>}
                                    </div>

                                    <ul className={`acc-item-content ps-4 ${showSubItem.includes(item.id) ? 'h-auto' : 'h-0'}`}>
                                        {item.sub.map((subItem) => (
                                            <li key={subItem.id} className='acc-sub-item'>
                                                <label htmlFor={subItem.id} className='dark-blue p-3 py-2 w-100 pointer d-flex align-items-center fs-14'>
                                                    {subItem.imgURL !== '' ? <img src={subItem.imgURL} alt={subItem.label} className='me-2 ' style={{width:'33px', height:'33px', padding:'3px'}}/> : <div className='me-2' style={{width:'25px', height:'25px'}}></div>}
                                                    <span className=''>{subItem.label}</span>
                                                </label>
                                                <input 
                                                    type="checkbox" 
                                                    id={subItem.id} 
                                                    name={subItem.label} 
                                                    className='me-3 checkbox-milky border' 
                                                    value={subItem.label} 
                                                    onChange={() => handleCheckboxChange(subItem)}
                                                />
                                            </li>
                                        ))}   
                                    </ul>
                                </li>
                            ;
                            return accordionItem;
                        })}
                    </>
                }

            </ul>

            <div className="col-12 d-block d-lg-none overflow-hidden mb-3">
                <ul className='d-flex flex-nowrap flex-lg-column list-unstyled overflow-auto m-0 text-nowrap'>
                {accordionList.map((item) => (
                    <li 
                        key={item.id} 
                        style={{background: item.checked ? "#D5DBFF" : 'transparent'}}
                        className={item.id !== accordionList.length ? ' filter-doc d-center flex-column acc-item-header dark-blue border-light-gray rounded fs-12 me-2 me-lg-0 mb-3 px-3 p-1' : ' filter-doc d-center flex-column acc-item-header dark-blue border-light-gray rounded fs-12 mb-3 px-3 p-1'}>
                        <label htmlFor={item.id} className='dark-blue p-3 w-100 pointer d-flex align-items-center'>
                            {item.imgURL !== '' ? <img src={item.imgURL} alt={item.label} className='me-2' style={{width:'30px', height:'30px'}}/> : <div className='me-2' style={{width:'30px', height:'30px'}}></div>}
                            <span>{item.label}</span>
                        </label>
                        <input 
                            type="checkbox" 
                            id={item.id} 
                            name={item.label} 
                            checked={item.checked}
                            className='me-3 checkbox-milky d-none' 
                            value={item.label} 
                            readOnly = {!isEmpty(item.sub)}
                            onChange={isEmpty(item.sub) ? () => handleCheckboxChange(item) : null}
                        />
                    </li>
                ))}
                </ul>
            </div>
        </div>
    )
}

export default Accordion;