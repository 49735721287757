import React from 'react';
import {range} from 'lodash';

const Pagination = ({totalHouses, currentPage, perPage, onPageChange}) => {

  const pageCount = Math.ceil(totalHouses / perPage);
  if(pageCount <= 1) return null;
  
  const pages = range(1, pageCount + 1);

  return (
    <ul className="pagination m-0 overflow-auto"> 
        <li className="page-item">
        <span className="page-link pointer" aria-label="Previous" onClick={() => onPageChange(1) } >
            <span aria-hidden="true">&laquo;&laquo;</span>
            <span className="sr-only">First</span>
        </span>
        </li>

        <li className="page-item">
        <span className="page-link pointer" aria-label="Previous" onClick={() => {
          if(currentPage !== 1) {
            onPageChange(currentPage-1)  
          }
        }} >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
        </span>
        </li>

        {pages.map(page => (
          <li key={page} className={`page-item ${page === currentPage ? 'active' : null}`}>
            <span className="page-link pointer" onClick={() => onPageChange(page)}>
              {page}
            </span>
          </li> 
        ))}

        <li className="page-item">
          <span className="page-link pointer" aria-label="Next" onClick={() => {
            if(currentPage < pageCount) {
              onPageChange(currentPage+1)
            }
          }}>
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">Next</span>
          </span>
        </li>

        <li className="page-item">
          <span className="page-link pointer" aria-label="Next" onClick={() => onPageChange(pageCount)}>
              <span aria-hidden="true">&raquo;&raquo;</span>
              <span className="sr-only">Last</span>
          </span>
        </li>

    </ul>
  )
}

export default Pagination;
