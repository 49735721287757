import { createContext } from "react";

export const context = createContext({
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
    policy: "",
    isVisible:  null,
    isVisibleConfirm: null,
    loading:  null,
    validator: null,
    loginValidator: null,
    registerValidator: null,
    rolesOption: [],

    setEmail: () => {},
    setPassword: () => {},
    setConfirmPassword: () => {},
    setRole: () => {},
    setPolicy: () => {},
    setVisible: () => {},
    setVisibleConfirm: () => {},

    handleLogin: () => {},
    handleRegister: () => {}
});