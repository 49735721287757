import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { isEmpty } from 'lodash';

import { tableHeader } from '../../data/constant';

const HousesList = ({housesList , houses}) => {
    return (  
        <section className='row m-0'>
            <div className='col-12 px-1 overflow-hidden'>
                <div className='t-head mb-2 py-1'>   
                    <div className='px-2'>
                        {!isEmpty(houses) ? 
                            <ul className='house-header mb-0'>
                                {tableHeader.map( (header, index) => {
                                    const item = index === 0 ? 
                                    <li key={index} className='house-header-content px-1' style={{maxWidth:header.width, width:header.width}}>
                                        {header.name}
                                    </li> :
                                    <li key={index} className='house-header-content px-3' style={{width:header.width}}>
                                        {header.name}
                                    </li>
                                    return item;
                                })}
                            </ul>
                        : 
                            <Skeleton variant='rectangle' height={44} className='rounded m-0'/>
                        }
                    </div>
                    <div className='house-list-wrapper p-2'>
                        {!isEmpty(houses) ?
                            housesList.map(house => (
                                <Link key={house.quid} to={`/house-details/${house.quid}`} className='house-list-item pointer text-decoration-none'>
                                    <li className='house-content justify-content-start' style={{width:"67px", maxWidth:"67px"}}>
                                        <img style={{maxWidth:'67px'}} className='w-100 h-100' src="/images/house.png" alt="" />
                                    </li>
                                    <li className='house-content px-3' style={{width:"180px"}}> {house.attributes.regid} </li>
                                    <li className='house-content px-3' style={{width:"200px"}}> {house.attributes.regdate} </li>                                        
                                    <li className='house-content px-3' style={{width:"120px"}}> {house.attributes.geo_state} </li>        
                                    <li className='house-content px-3' style={{width:"100px"}}> {house.attributes.geo_region} </li>    
                                    <li className='house-content px-3' style={{width:"80px"}}> {house.attributes.geo_zip} </li>        
                                    <li className='house-content px-3' style={{width:"170px"}}> {house.attributes.address} </li>        
                                    <li className='house-content px-3 word-break' style={{width:"320px"}}> {house.attributes.owner[0]} </li>
                                </Link>
                            ))
                            : 
                            <>
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />
                                <Skeleton variant='rectangle' height={67} className='rounded' sx={{ margin:'10px 0 20px' }} />  
                            </>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default HousesList;