const YouTubeVideo = ({src}) => {
    return (
        <iframe 
            className='w-100 h-100'
            src={src} 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen="allowfullscreen"
            // mozallowfullscreen="mozallowfullscreen" 
            // msallowfullscreen="msallowfullscreen" 
            // oallowfullscreen="oallowfullscreen" 
            // webkitallowfullscreen="webkitallowfullscreen"
        ></iframe>
    )
}

export default YouTubeVideo;