// export const getBase64 = (file, callback) => {
//     let reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => callback(reader.result);
//     reader.onerror = err => console.log('Error: ', err);
// }

export const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => reader.result;
    reader.onerror = err => {
      // console.log('Error: ', err);
    }
}


export const readUploadedFile = (inputFile) => {
    const temporaryFileReader = new FileReader();
  
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
  
      temporaryFileReader.onloadend = (file) => {
        // resolve({ name: new Date().getTime() + "-" + inputFile.name, binary: temporaryFileReader.result, mimeType: inputFile.type });
        resolve({ name: inputFile.name, binary: temporaryFileReader.result, mimeType: inputFile.type });
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };