import { getHouseInformation } from "../../services/houseService";
import { getUserInformation } from "../../services/userService";

export const getUserInfo = user_quid => {
    return async dispatch => {
        const {data} = await getUserInformation(user_quid);
        await dispatch({type:"GET_USER_INFO", payload: data});
        if(data.attributes.owns) {
            const {owns} = data.attributes;
            // owns.map(async own => {
            //     const {data} = await getHouseInformation(own);
            //     await dispatch({type: "GET_HOUSES", payload: data})
            //     await dispatch({type: "GET_HOUSES_FILTER", payload: data})
            // });

            const promise = [];
            owns.map(own => promise.push(getHouseInformation(own)));

            Promise.all(promise).then(result => {
                // console.log(result)
                const houses =[];
                result.map(res => houses.push(res.data));
                dispatch({type: "GET_HOUSES", payload: houses});
                dispatch({type: "GET_HOUSES_FILTER", payload: houses});
            })
            .catch(ex => 
                {
                    // console.log({ex})
                }
            );
        }
    }
}

export const clearUserInfo = () => {
    return async dispatch => {
        await dispatch({type:"CLEAR_USER_INFO", payload: {}});
    }
}