import { isEmpty } from 'lodash';
import { RingLoader } from 'react-spinners';

const AddImageDialog = ({closeModal, imageSubmit, deleteImage, uploadImage, images, btnRef, btnLoading, }) => {
    return (
        <div className='customModal'> 
            <div className='h-100 vw-100 overflow-auto'>
                <div className='modal-box'>

                    <div className="row pt-1 pb-3 mb-4 border-bottom-light-gray">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <h4 className='py-2 my-auto dark-purple bold'>Add Image</h4>
                            <i className="fa fa-times fa-lg pointer p-2" aria-hidden="true" onClick={closeModal}></i>
                        </div>
                    </div>

                    <form  onSubmit={imageSubmit}>
                        <div className="row mb-4 border-bottom-light-gray">
                            {/* IMAGES */}
                            <section className="row m-0">   
                                <div className="col-12 p-0">
                                    <div className="row m-0">
                                        <label className="col-6 dark-purple bold fs-16 text-uppercase mb-2 p-0"> Images </label>
                                        <div className='col-12 p-0 d-flex flex-wrap'>                             
                                            {!isEmpty(images) &&
                                                images.map((image, index) => (
                                                    <div key={index} className='m-2' >
                                                        <img className='b-shadow-mid-dark p-2 rounded' style={{width:'120px', height:'120px'}} src={image? URL.createObjectURL(image) : null} alt={image? image.name : null}/>
                                                        
                                                        <div  className='text-center my-2'>   
                                                            <i className="fa fa-times fa-lg p-2 pointer" aria-hidden="true" onClick={() => deleteImage(index)}></i>
                                                        </div>
                                                    </div>         
                                                ))
                                            }
                                        </div>
                                        <div className="col-12 mb-2 p-0">
                                            <div className="custom-file-upload">
                                                <span>Please choose image(s)</span>
                                                <label htmlFor="images" className='custom-btn btn-orange-outline pointer d-center' style={{height:'36px'}}> Choose image </label>
                                                <input 
                                                    type="file" 
                                                    multiple={true}
                                                    accept="image/*"
                                                    name="images" 
                                                    id="images"
                                                    value=''    // only this line solve my problem "Uploading File doesn't let me pick same file after I remove it from selection"
                                                    className="form-input custom-file-upload" 
                                                    placeholder="No file selected"
                                                    onChange={e => uploadImage(e)}
                                                />
                                            </div>
                                            <div className='input-error-message'> </div>    
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>  
                        {/* <div className="row pt-1 ">
                            <div className="col-12 d-flex justify-content-end">
                                <button className='custom-btn btn-midnight-fill' type='submit' >Add Image</button>
                            </div>
                        </div> */}

                        <div className="row pt-1">
                            <div className="col-12 d-flex justify-content-end">
                                <button ref={btnRef} className='custom-btn btn-milky-fill' type='submit'>
                                    {btnLoading ? 
                                        <div className='w-100 h-100 d-center'> 
                                            <RingLoader color='#312782' loading={btnLoading} size={25}/> 
                                        </div>  
                                    : 'Add Image'}
                                </button>
                            </div>
                        </div>

                    </form> 

                </div>
            </div>
        </div>
    )
}

export default AddImageDialog;