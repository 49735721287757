import Select from 'react-select';
import format from 'date-fns/format';
import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from "react-datepicker";
import { RingLoader } from 'react-spinners';

const AddHouseDialog = ({
        addHouseSubmit, 
        closeModal, 
        options, 
        selectStyle, 
        handleDeleteImage, 
        handleUploadImage, 
        handleDeleteDocument, 
        handleUploadDocument, 
        validator,
        user, countriesName, regionsName,
        registrationId, setRegistrationId,
        houseName, setHouseName,
        country, setCountry,
        region, setRegion,
        city, setCity,
        zipCode, setZipCode,
        address, setAddress,
        date, setDate,
        documents, setDocuments,
        images, btnLoading, btnRef}) => {

    return (
        
        <div className='customModal'> 
            <div className='h-100 vw-100 overflow-auto'>
                <div className='modal-box'>
                    <div className="row pt-1 pb-3 mb-4 border-bottom-light-gray">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <h4 className='py-2 my-auto dark-purple bold'>Add new house</h4>
                            <i className="fa fa-times fa-lg pointer p-2" aria-hidden="true" onClick={closeModal}></i>
                        </div>
                    </div>

                    <form onSubmit={addHouseSubmit}>
                        <div className="row mb-4 border-bottom-light-gray">

                            {/* REGISTRATION ID */}
                            <div className="col-12 mb-2">
                                <div className='d-flex flex-column-reverse flex-md-row'>
                                    <label htmlFor="registration_id" className="dark-purple bold fs-16 text-uppercase mb-2">
                                        <span>Registration ID</span> 
                                        <span className='required-color'> * </span>
                                    </label>
                                    <div className='ms-md-auto text-center mb-2'>How to register your house ID based on the country you are in? 
                                        <a href='https://www.youtube.com/embed/IfTj8KJHxb8' target='_blank' className='milky text-decoration-none'> Click here!</a>
                                    </div>
                                </div>
                                <input 
                                    type="text" 
                                    name="registration_id" 
                                    id="registration_id" 
                                    className="form-input"
                                    placeholder="example: SU-F145-P401-Sb104-Z3-Mz-CA3-Cl4-Sc88" 
                                    value={registrationId}
                                    onChange={e => {
                                        setRegistrationId(e.target.value);
                                        validator.current.showMessageFor("registration_id");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {validator.current.message("registration_id", registrationId, "required")}
                                </div>
                            </div>

                            {/* HOUSE NAME */}
                            <div className="col-12 col-md-6 mb-2">
                                <label htmlFor="house_name" className="dark-purple bold fs-16 text-uppercase mb-2">
                                    House Name
                                    <span className='required-color'> * </span>
                                </label>
                                <input 
                                    type="text" 
                                    name="house_name" 
                                    id="house_name" 
                                    className="form-input" 
                                    placeholder="example: ......." 
                                    value={houseName}
                                    onChange={e => {
                                        setHouseName(e.target.value);
                                        validator.current.showMessageFor("house_name");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {validator.current.message("house_name", houseName, "required")}
                                </div>
                            </div>

                            {/* COUNTRY */}
                            <div className="col-12 col-md-6 mb-2">
                                <label htmlFor="country" className="dark-purple bold fs-16 text-uppercase mb-2">
                                    Country
                                    <span className='required-color'> * </span>
                                </label>
                                {/* <input 
                                    type="text" 
                                    name="country" 
                                    id="country" 
                                    className="form-input" 
                                    placeholder="example: ITALY"
                                    value={country}
                                    onChange={e => {
                                        setCountry(e.target.value);
                                        validator.current.showMessageFor("country");
                                    }}
                                /> */}

                                <Select 
                                    id="country" 
                                    name="country" 
                                    options={countriesName} 
                                    placeholder="select the country"
                                    isClearable  
                                    styles={selectStyle}
                                    onChange={e => {
                                        if(e !== null) {
                                            setCountry(e.value);
                                            if(e.value === 'Italy') setRegion('');
                                            // setRegion('DD');
                                            if(e.value !== 'Italy') setRegion('EE');
                                            // console.log(e.value)
                                        } 
                                        else {
                                            setCountry('');
                                            setRegion('');
                                        }


                                        // if(e.value === '') setRegion(''); 
                                        // if(e.value !== 'ITALY') setRegion('EE');
                                        validator.current.showMessageFor("country");
                                        // console.log(country)
                                    }}
                                    
                                />
                                <div className='input-error-message'>
                                    {validator.current.message("country", country, "required")}
                                </div>
                            </div>

                            {/* REGION */}
                            <div className="col-12 col-md-6 mb-2">
                                <label htmlFor="region" className="dark-purple bold fs-16 text-uppercase mb-2">
                                    Region
                                    <span className='required-color'> * </span>
                                </label>

                                {country !== 'Italy' ? 
                                    <input 
                                        type="text" 
                                        name="region" 
                                        id="region" 
                                        disabled
                                        className="form-input" 
                                        placeholder="region"
                                        value={region}
                                        // value="EE"
                                        onChange={e => {
                                            // setRegion('EE');
                                            validator.current.showMessageFor("region");
                                        }}
                                    />
                                :
                                    <Select 
                                        id="region" 
                                        name="region" 
                                        options={regionsName} 
                                        placeholder="select the region"
                                        isClearable  
                                        styles={selectStyle}
                                        // value={region}
                                        onChange={e => {
                                            if(e !== null) setRegion(e.value); else setRegion('');
                                            validator.current.showMessageFor("region");
                                        }}
                                    />
                                }

                                <div className='input-error-message'>
                                    {validator.current.message("region", region, "required")}
                                </div>
                            </div>

                            {/* CITY */}
                            <div className="col-12 col-md-6 mb-2">
                                <label htmlFor="city" className="dark-purple bold fs-16 text-uppercase mb-2">
                                    City
                                    <span className='required-color'> * </span>
                                </label>
                                <input 
                                    type="text" 
                                    name="city" 
                                    id="city" 
                                    className="form-input" 
                                    placeholder="example: Torino"
                                    value={city}
                                    onChange={e => {
                                        setCity(e.target.value);
                                        validator.current.showMessageFor("city");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {validator.current.message("city", city, "required")}
                                </div>
                            </div>
                            
                            {/* ZIP CODE */}
                            <div className="col-12 col-md-6 mb-2">
                                <label htmlFor="zip_code" className="dark-purple bold fs-16 text-uppercase mb-2">
                                    Zip Code
                                    <span className='required-color'> * </span>
                                </label>
                                <input 
                                    type="number" 
                                    name="zip_code" 
                                    id="zip_code" 
                                    className="form-input" 
                                    placeholder="example: 10134"
                                    value={zipCode}
                                    onChange={e => {
                                        setZipCode(e.target.value);
                                        validator.current.showMessageFor("zip_code");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {validator.current.message("zip_code", zipCode, "required|min:5|max:5")}
                                </div>
                            </div>

                            {/* ADDRESS */}
                            <div className="col-12 col-md-6 mb-2">
                                <label htmlFor="address" className="dark-purple bold fs-16 text-uppercase mb-2">
                                    Address
                                    <span className='required-color'> * </span>
                                </label>
                                <input 
                                    type="text" 
                                    name="address" 
                                    id="address" 
                                    className="form-input" 
                                    placeholder="example: Via Roma, 18"
                                    value={address}
                                    onChange={e => {
                                        setAddress(e.target.value);
                                        validator.current.showMessageFor("address");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {validator.current.message("address", address, "required")}
                                </div>
                            </div>

                            {/* OWNER ADDRESS */}
                            <div className="col-12 mb-2">
                                <label htmlFor="owner_address" className="dark-purple bold fs-16 text-uppercase mb-2">
                                    Owner Address
                                    <span className='required-color'> * </span>
                                </label>
                                <input
                                    readOnly
                                    disabled
                                    type="text" 
                                    name="owner_address" 
                                    id="owner_address" 
                                    className="form-input" 
                                    value={user || ''}
                                />
                                <div className='input-error-message'></div>
                            </div>

                            {/* DATE TIME */}
                            <div className="col-12 mb-2">
                                <label htmlFor="date" className="dark-purple bold fs-16 text-uppercase mb-2">
                                    Date time
                                    <span className='required-color'> * </span>
                                </label>
                                <DatePicker 
                                    name='date'
                                    id='date'
                                    disabled
                                    className='form-input' 
                                    selected={new Date()} 
                                    dateFormat="yyyy/MM/dd"
                                    value={date}
                                    onChange={(date) => {
                                        const selected_date = format(date, "yyyy/MM/dd")
                                        setDate(selected_date);
                                        validator.current.showMessageFor("date");
                                    }} 
                                />
                                <div className='input-error-message'>
                                    {validator.current.message("date", date, "required")}
                                </div>
                            </div>

                            {/* IMAGES */}
                            <section className="row m-0">   
                                <div className="col-12 p-0">
                                    <div className="row m-0">
                                        <label className="col-6 dark-purple bold fs-16 text-uppercase mb-2 p-0"> Images </label>
                                        <div className='col-12 p-0 d-flex flex-wrap'>                             
                                            {!isEmpty(images) &&
                                                images.map((image, index) => (
                                                    <div key={index} className='m-2' >
                                                        <img className='b-shadow-mid-dark p-2 rounded' style={{width:'120px', height:'120px'}} src={image? URL.createObjectURL(image) : null} alt={image? image.name : null}/>
                                                        
                                                        <div  className='text-center my-2'>   
                                                            <i className="fa fa-times fa-lg p-2 pointer" aria-hidden="true" onClick={() => handleDeleteImage(index)}></i>
                                                        </div>
                                                    </div>         
                                                ))
                                            }
                                        </div>
                                        <div className="col-12 mb-2 p-0">
                                            <div className="custom-file-upload">
                                                <span>Please choose an image</span>
                                                <label htmlFor="images" className='custom-btn btn-orange-outline pointer d-center' style={{height:'36px'}}> Choose image </label>
                                                <input 
                                                    type="file" 
                                                    multiple={true}
                                                    accept="image/*"
                                                    name="images" 
                                                    id="images"
                                                    value=''    // only this line solve my problem "Uploading File doesn't let me pick same file after I remove it from selection"
                                                    className="form-input custom-file-upload" 
                                                    placeholder="No file selected"
                                                    onChange={e => handleUploadImage(e)}
                                                />
                                            </div>
                                            <div className='input-error-message'> </div>    
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* DOCUMENTS */}
                            <section className="row m-0">   
                                <div className="col-12 p-0">
                                    <div className="row m-0">
                                        <label className="col-6 dark-purple bold fs-16 text-uppercase mb-2 p-0"> Documents </label>
                                        {/* <label className={`col-6 dark-purple bold fs-16 text-uppercase mb-2 ${isEmpty(documents) ? 'd-none' : 'd-block'}`}> File Type </label>                                               */}
                                        {!isEmpty(documents) &&
                                            documents.map((document, index) => (
                                                <section key={index} className='row m-0 p-0 py-2 mb-3'>
                                                    <div className='col-12 col-md-6 p-0 mb-2 pe-md-2'>
                                                        <div className=" custom-file-upload word-break bg-white">
                                                            <span className='w-75 h-100 pe-2 overflow-hidden d-flex align-items-center'>{document.name}</span>
                                                            <div 
                                                                className='custom-btn btn-rose-gold-fill d-center pointer' 
                                                                style={{height:'36px'}}
                                                                onClick={() => handleDeleteDocument(index)}    
                                                            >   Delete
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 ps-md-2 p-0 mb-2'>
                                                        <Select 
                                                            id="file_type" 
                                                            name="file_type" 
                                                            options={options} 
                                                            placeholder="select the file type"
                                                            isClearable  
                                                            styles={selectStyle}
                                                            value={{value: document.type || '' , label: document.type || ''}}
                                                            // defaultValue={{value: document.type || ''}}

                                                            onChange={(e) => {
                                                                const docs = [...documents];
                                                                const selectedDoc = docs[index];
                                                                selectedDoc.type =  e && e.value ? e.value : '';
                                                                setDocuments(docs);
                                                                validator.current.showMessageFor("file_type");
                                                                
                                                            }}
                                                        />

                                                        <div className='input-error-message'>
                                                            {validator.current.message("file_type", document.type, "required")}
                                                        </div>

                                                    </div>
                                                    <div className='col-12 p-0 '>
                                                        <input 
                                                            type="text" 
                                                            name="description" 
                                                            id="description" 
                                                            className="form-input" 
                                                            placeholder="example: Gas certificate"
                                                            // onChange={e => document.description = e.target.value}
                                                            value={document.description}
                                                            onChange={e => {
                                                                const docs = [...documents];
                                                                const selectedDoc = docs[index];
                                                                selectedDoc.description =  e.target.value ? e.target.value : '';
                                                                setDocuments(docs);
                                                            }}
                                                        />
                                                    </div>
                                                </section>
                                            ))
                                        }
                                        <div className="col-12 mb-2 p-0">
                                            <div className=" custom-file-upload">
                                                <span>Please Choose the file</span>
                                                <label htmlFor="documents" className='custom-btn btn-orange-outline pointer d-center' style={{height:'36px'}}> Choose file </label>
                                                <input 
                                                    type="file" 
                                                    name="documents" 
                                                    id="documents" 
                                                    className="form-input custom-file-upload" 
                                                    placeholder="No file selected"
                                                    onChange={e => handleUploadDocument(e)}
                                                />
                                            </div>
                                            <div className='input-error-message'> </div>    
                                        </div>
                                    </div>
                                </div>
                            </section>
                            
                        </div>  
                        {/* <div className="row pt-1 ">
                            <div className="col-12 d-flex justify-content-end">
                                <button className='custom-btn btn-milky-fill' type='submit' >Add house</button>
                            </div>
                        </div> */}

                        <div className="row pt-1">
                            <div className="col-12 d-flex justify-content-end">
                                <button ref={btnRef} className='custom-btn btn-milky-fill' type='submit'>
                                    {btnLoading ? 
                                        <div className='w-100 h-100 d-center'> 
                                            <RingLoader color='#312782' loading={btnLoading} size={25}/> 
                                        </div>  
                                    : 'Add house'}
                                </button>
                            </div>
                        </div>


                    </form>   
                </div>    
            </div>
        </div>
    )
}

export default AddHouseDialog;