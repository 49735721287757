export const houseDocumentsReducer = (state = [], action) => {
    switch(action.type) {
        case "GET_DOCUMENTS":
            return [...action.payload];
        case "ADD_DOCUMENTS":
            return [...action.payload];
        case "CLEAR_DOCUMENTS":
            return[...action.payload];
        default:
            return state;
    }
}