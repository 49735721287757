export const filterHousesReducer = (state = [], action) => {
    switch(action.type) {
        case "GET_HOUSES_FILTER":
            return [...action.payload];
        
        case "ADD_FILTER_HOUSE":
            return [...action.payload];

        case "FILTER_HOUSES":
            return [...action.payload];

        case "CLEAR_FILTER_HOUSES":
            return [...action.payload];

        default:
            return state;
    }
}