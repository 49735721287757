import { useContext, useEffect } from 'react';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';

import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet-async';
import  {Skeleton}  from "@mui/material";

import { context } from '../../context/house/context';
import HousesList from './HousesList';
import Filter from '../../components/ui/Filter';
import Modal from '../../components/ui/modal/Modal';
import Pagination from '../../components/ui/Pagination';
import Button from '../../components/ui/Button';

const Houses = () => {

    const housesContext = useContext(context);
    const { id, token, location, userInfo,
        housesList, filteredHouses, houses, closeHousesModal,
        showFilter, setShowFilter, 
        activeHomeModal, setActiveHomeModal, 
        activePaymentModal, setActivePaymentModal, 
        addhouseId, setAddhouseId,
        perPage, setPerPage, 
        currentPage, setCurrentPage, 
        handlePerPage, handlePageChange } = housesContext;

    useEffect(() => {
        setPerPage(10);
        setCurrentPage(1);
        if (location.pathname === '/houses/payment') {
            setActivePaymentModal(true);
        }
        if (location.pathname === `/houses/add-house/${id}`) {
            setActiveHomeModal(true);
            setAddhouseId(id);
        }
    }, []);

    if(isEmpty(token)) return <Navigate to="/login" replace/> ;
      
    return (  
        <main>
            <Helmet>
                <title>QR-House | Houses</title>
            </Helmet>
            {userInfo && userInfo.attributes && isEmpty(userInfo.attributes.owns) ?
                <div>
                    <div className='text-center p-2 p-md-5' style={{marginTop:'100px'}}>    
                        <img src="/images/add_house@2x.png" alt="Add House" className='w-100' style={{maxWidth:'574px'}}/>  
                        <h3 className='mt-5 purple bold text-uppercase'>There are no houses listed</h3>    
                        <p className='dark-purple'>Add your first home and start tracking your documents</p>
                        <button className='custom-btn btn-milky-fill' onClick={() => setActivePaymentModal(true)}>Add House</button> 
                    </div>
                    <Modal activeHomeModal={activeHomeModal} activePaymentModal={activePaymentModal} addhouseId={addhouseId} closeModal={closeHousesModal}/>
                </div>
            :
                <section className='max-container m-auto p-container overflow-auto'>
                    <div className='row mb-4 m-0'>
                        { isEmpty(houses) ? 
                            <h4 className='col-12 col-sm-6 my-auto dark-purple bold'> 
                                <Skeleton variant='rectangle' sx={{width:'120px', height:'40px'}} className='my-1'/> 
                            </h4> : 
                            <h4 className='col-12 col-sm-6 py-2 my-auto dark-purple bold'>List house</h4>
                        }
                        <div className='col-12 col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center mt-2 mt-sm-0'>
                            { isEmpty(houses) ? 
                                <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px', marginRight:'1rem'}} /> :
                                <button className={'custom-btn btn-midnight-outline me-3'} onClick={() => setShowFilter(!showFilter)}>Filters</button>
                            }

                            {isEmpty(houses) ? 
                                <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px', marginRight:'1rem'}} /> :
                                <Link to='/houses/payment' className='d-inline-block round-50 me-3' onClick={() => setActivePaymentModal(true)}> 
                                    <Button text='Add House' bg='milky-fill' /> 
                                </Link>
                            }
                            {isEmpty(houses) ? 
                                <Skeleton variant='circle' sx={{width:'40px', height:'40px', borderRadius:'50px'}} /> :
                                // <Link to='/houses/payment' className='d-inline-block round-50' onClick={() => setActivePaymentModal(true)}> 
                                //     <Button text='Add House' bg='milky-fill' /> 
                                // </Link>
                                <div className='video-icon position-relative' style={{width:'40px', height:'40px'}}>
                                    <a href="https://www.youtube.com/embed/7T56E1hQmNU" className='bg-light-purple-op circle w-100 h-100 d-center' target='_blank'>
                                        <img src="/images/icons/video_icon@2x.png" alt="video" className='w-50 h-50'/>
                                    </a>
                                    <span className='video-tooltip'>Play video tutorial</span>
                                </div>
                                
                            }
                        </div>
                    </div>
                    <Filter allHouses={houses} showFilter={showFilter} setCurrentPage={setCurrentPage}/>
                    <HousesList housesList={housesList} houses={houses}/>
                    <div className="d-flex flex-column-reverse flex-sm-row position-relative justify-content-lg-center justify-content-end mt-3 px-2" style={{minHeight:'45px'}}>
                        <div className="select-perPage">
                            {!isEmpty(houses) && 
                                <div className='d-inline-flex align-items-center'>
                                    <label className="me-3 d-inline-block text-nowrap ps-2">Show</label>
                                    <select className="form-select" aria-label="select number of rows" onChange={handlePerPage} value={perPage}>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                    </select>
                                </div>
                            } 
                        </div>
                        <div className='d-flex justify-content-center overflow-hidden mb-2 mb-sm-0 pe-1'>
                            <Pagination totalHouses={filteredHouses.length} currentPage={currentPage} perPage={perPage} onPageChange={handlePageChange} />
                        </div>
                    </div>
                    <Modal activeHomeModal={activeHomeModal} activePaymentModal={activePaymentModal} addhouseId={addhouseId} closeModal={closeHousesModal}/>
                </section>
            }
        </main>
    );
}
 
export default Houses;