import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns'

import { filterAllHouses } from '../../redux/actions/houses';
import { houseFilter } from '../../utils/filter';

const Filter = ({allHouses, showFilter, setCurrentPage}) => {

    const dispatch = useDispatch();

    const [ownerFilter, setOwnerFilter] = useState("");
    const [houseIdFilter, setHouseIdFilter] = useState("");
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [regionFilter, setRegionFilter] = useState("");
    const [zipCodeFilter, setZipCodeFilter] = useState("");
    const [addressFilter, setAddressFilter] = useState("");
    const [countryFilter, setCountryFilter] = useState("");

    const filterInputs = {
        ownerFilter, 
        houseIdFilter,
        startDateFilter: new Date(startDateFilter).getTime() / 1000, 
        endDateFilter: new Date(endDateFilter).getTime() / 1000, 
        regionFilter, 
        zipCodeFilter, 
        addressFilter, 
        countryFilter
    }
    const handleHouseFilter = () => {
        const filterResult = houseFilter(allHouses, filterInputs); 
        setCurrentPage(1);
        dispatch(filterAllHouses(filterResult));
    }

    const handleResetFilter = () => {
        resetStates();
        dispatch(filterAllHouses(allHouses));
    }

    const resetStates = () => {
        setOwnerFilter("");
        setHouseIdFilter("");
        setStartDateFilter(null);
        setEndDateFilter(null);
        setRegionFilter("");
        setZipCodeFilter("");
        setAddressFilter("");
        setCountryFilter("");
    }
    
    return (  
        <>
            {showFilter ?
                <div className='mb-5 bg-silverS'>
                    <ul className='row m-0 list-unstyled'>
                        {/* OWNER FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='owner' className='mb-2 purple bold'>Owner</label>
                            <input 
                                type='text' 
                                id='owner'  
                                name='owner'
                                placeholder='Insert Owner ID' 
                                className='custom-input d-block'
                                value={ownerFilter}
                                onChange={e => {
                                    setOwnerFilter(e.target.value);
                                    // fetchData(e.target.value)
                                
                                }}
                            />   
                        </li>
        
                        {/* HOUSE ID FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='owner' className='mb-2 purple bold'>House ID</label>
                            <input 
                                type='text' 
                                id='house_id'  
                                name='house_id'
                                placeholder='Insert House ID' 
                                className='custom-input d-block'
                                value={houseIdFilter}
                                onChange={e => setHouseIdFilter(e.target.value)}
                            />   
                        </li>
        
                        {/* DATE START FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='date_start' className='mb-2 purple bold'>Date start</label>
                            <ReactDatePicker 
                                type='date' 
                                id='date_start'  
                                name='date_start' 
                                className='custom-input d-block' 
                                selected={startDateFilter ? new Date(startDateFilter) : null} 
                                dateFormat="EEE MMMM dd yyyy"
                                placeholderText='Insert Start Date'
                                value={startDateFilter}
                                onChange={(date) => {
                                    const selected_date = format(date, "EEE MMMM dd yyyy")
                                    setStartDateFilter(selected_date);
                                }} 
                            />  
                        </li>
        
                        {/* DATE END FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='date_end' className='mb-2 purple bold'>Date end</label>
                            <ReactDatePicker 
                                type='date' 
                                id='date_end'  
                                name='date_end' 
                                className='custom-input d-block' 
                                selected={endDateFilter ? new Date(endDateFilter) : null}  
                                dateFormat="EEE MMMM dd yyyy"
                                placeholderText='Insert End Date'
                                value={endDateFilter}
                                onChange={(date) => {
                                    const selected_date = format(date, "EEE MMMM dd yyyy")
                                    setEndDateFilter(selected_date);
                                }} 
                            />   
                        </li>
        
                        {/* REGION FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='region' className='mb-2 purple bold'>Region</label>
                            <input 
                                type='text' 
                                id='region'  
                                name='region'
                                placeholder='Insert region'  
                                className='custom-input d-block'
                                value={regionFilter}
                                onChange={e => setRegionFilter(e.target.value)}
                            />   
                        </li>
        
                        {/* POSTAL CODE FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='zip_code' className='mb-2 purple bold'>Zip code</label>
                            <input 
                                type='number' 
                                id='zip_code'  
                                name='zip_code' 
                                placeholder='Insert zip code' 
                                className='custom-input d-block'
                                value={zipCodeFilter}
                                onChange={e => setZipCodeFilter(e.target.value)}
                            />   
                        </li>
        
                        {/* ADDRESS FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='address' className='mb-2 purple bold'>Address</label>
                            <input 
                                type='text' 
                                id='address'  
                                name='address' 
                                placeholder='Insert address' 
                                className='custom-input d-block'
                                value={addressFilter}
                                onChange={e => setAddressFilter(e.target.value)}
                            />   
                        </li>
        
                        {/* COUNTRY FILTER */}
                        <li className='col-12 col-md-6 col-lg-4 col-xl-3 py-3'>
                            <label htmlFor='country' className='mb-2 purple bold'>Country</label>
                            <input 
                                type='text' 
                                id='country'  
                                name='country' 
                                placeholder='Insert country' 
                                className='custom-input d-block'
                                value={countryFilter}
                                onChange={e => setCountryFilter(e.target.value)}
                            />   
                        </li>
                    </ul>
                    <div className='row m-0'>
                        <div className='col-12 d-flex justify-content-end my-3'>
                            <button className='custom-btn btn-rose-gold-fill me-3' onClick={handleResetFilter}>Reset</button>
                            <button className='custom-btn btn-midnight-outline' onClick={handleHouseFilter}>Apply</button>
                            {/* <Link to={`/houses?${!isEmpty(countryFilter) ? `country=${countryFilter}` : ''}${!isEmpty(addressFilter) ? `address=${addressFilter}` : ''}`}>
                            <button className='custom-btn btn-midnight-outline' >Apply</button>
                            </Link> */}
                        </div>
                    </div>
                </div>
            : null}
        </>
    );
}
 
export default Filter;