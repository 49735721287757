import id from 'date-fns/esm/locale/id/index.js';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { changePassword, changeProfile, getUserInformation } from '../../services/userService';
import { errorMessage, successMessage } from '../../utils/message';

import RingLoader from "react-spinners/RingLoader";
import { getUserInfo } from '../../redux/actions/userInfo';

import { select_style, select_style_profile } from '../../components/ui/SelectStyle';
import Select from 'react-select';
import { isEmpty } from 'lodash';

const Profile = () => {

    const {user_quid} = useSelector(state => state.user);
    const userInfo = useSelector(state => state.userInfo);
    const countries = useSelector(state => state.countries);

    const countriesName=[];
    countries.map(country => countriesName.push({value: country.iso_code, label: country.name}));

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [isModify, setModify] = useState(false);

    const [firstname, setFirstname] = useState(userInfo.person_name);
    const [lastname, setLastname] = useState(userInfo.person_surname);
    const [city, setCity] = useState(userInfo.city);
    const [zipCode, setZipCode] = useState(userInfo.zip_code);
    const [country, setCountry] = useState({value: userInfo.country , label: userInfo.country_name});
    const [address, setAddress] = useState(userInfo.address);
    const [company, setCompany] = useState(userInfo.company_name);
    const [vat, setVat] = useState(userInfo.vat_number);
    const [, forceUpdate] = useState();

    const validator = useRef(new SimpleReactValidator({
        messages: {},
        element: message => <div style={{color:'#ef5350'}}>{message}</div>
    }));

    const handleProfile = async event => {
        event.preventDefault();        
        const user_profile = { 
            person_name: firstname,
            person_surname: lastname,
            email: userInfo.email, 
            country:country.value,
            city: city,
            zip_code: zipCode,
            address: address, 
            company_name: company, 
            vat_number: vat
        };
        try {

            if(validator.current.allValid()) {
                // console.log(user_profile);
                setLoading(true);
                const { data, status } = await changeProfile(user_profile);
                if(status === 200) {
                    // console.log(data);
                    successMessage("The Profile is updated successfully");
                    const newUserInfo = await getUserInformation(user_quid);
                    dispatch({type:"GET_USER_INFO", payload: newUserInfo.data});
                    setLoading(false);
                    setModify(false);
                }
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }  
        } catch(ex) {
            if(ex.response.status === 400) {
                setLoading(false);
                errorMessage("The VAT Number has wrong format!");
                // console.log(ex);

            }
            else {
                setLoading(false);
                errorMessage("Error : Update Profile has been failed");
                // console.log(ex);
            }
        }    
    }

    useEffect(() => {
        setFirstname(userInfo.person_name);
        setLastname(userInfo.person_surname);
        setCountry({value: userInfo.country , label: userInfo.country_name});
        setCity(userInfo.city);
        setZipCode(userInfo.zip_code);
        setAddress(userInfo.address);
        setCompany(userInfo.company_name);
        setVat(userInfo.vat_number);
    }, [userInfo]);

    const handleModify = () => {
        setModify(true);
        // setFirstname(userInfo.person_name);
        // setLastname(userInfo.person_surname);
        // setCity(userInfo.city);
        // setZipCode(userInfo.zip_code);
        // setCountry({value: userInfo.country , label: userInfo.country_name});
        // setAddress(userInfo.address);
        // setCompany(userInfo.company_name);
        // setVat(userInfo.vat_number);
    }

    const handleCancel = () => {
        setModify(false);
        setFirstname(userInfo.person_name);
        setLastname(userInfo.person_surname);
        setCity(userInfo.city);
        setZipCode(userInfo.zip_code);
        setCountry({value: userInfo.country , label: userInfo.country_name});
        setAddress(userInfo.address);
        setCompany(userInfo.company_name);
        setVat(userInfo.vat_number);
    }

  return (
    <>
        <h4 className='dark-purple bold mb-5'>User profile</h4> 
        {loading ?
            <div className="">
                <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                    <RingLoader color='#312782' loading={loading} size={80} />
                </div>
            </div>
            :
            <>
                <form onSubmit={handleProfile}>

                    <div className="d-flex flex-wrap"> 
                        {/* FIRST NAME */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="name" className="d-block mb-2"> Name <span className='required-color'> * </span> </label>
                            <div className="position-relative">
                                <input 
                                    type='search'
                                    disabled={!isModify} 
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='name' 
                                    name='name'
                                    value={firstname}
                                    onChange={e => {
                                        setFirstname(e.target.value);
                                        validator.current.showMessageFor("name");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {isModify && validator.current.message("name", firstname, "required")}
                                </div>
                            </div>
                        </div> 

                        {/* LAST NAME */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="surname" className='d-block mb-2'> Surname <span className='required-color'> * </span> </label>
                            <div className="position-relative">
                                <input 
                                    type='search'
                                    disabled={!isModify} 
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='surname' 
                                    name='surname'
                                    value={lastname}
                                    onChange={e => {
                                        setLastname(e.target.value);
                                        validator.current.showMessageFor("surname");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {isModify && validator.current.message("surname", lastname, "required")}
                                </div>
                            </div>
                        </div> 

                        {/* EMAIL */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="email" className='d-block mb-2'> Email <span className='required-color'> * </span> </label>
                            <input 
                                disabled={true}
                                className='form-input bg-light-skyblue'
                                value={userInfo.email}
                            />
                            <div className='input-error-message'> </div>
                        </div>

                        {/* COUNTRY */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="country" className='d-block mb-2'> Country <span className='required-color'> * </span> </label>
                            <div className="position-relative">
                                {isModify ?
                                    <>
                                        <Select 
                                            id="country" 
                                            name="country" 
                                            options={countriesName} 
                                            isClearable  
                                            isDisabled={!isModify}
                                            styles={select_style_profile}
                                            defaultValue={{value: country.value, label: country.label}}
                                            onChange={e => {
                                                // console.log(e && e.value);
                                                setCountry(e ? {value: e.value, label: e.label} : '');
                                                validator.current.showMessageFor("country");
                                            }}  
                                        />
                                        <div className='input-error-message'>
                                            {isModify && validator.current.message("country", country, "required")}
                                        </div> 
                                    </>
                                    :
                                    <input
                                        className='form-input bg-light-skyblue'
                                        disabled={true} 
                                        value={country.label}
                                    />  
                                } 
                            </div>
                        </div> 

                        {/* CITY */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="city" className='d-block mb-2'> City <span className='required-color'> * </span> </label>
                            <div className="position-relative">
                                <input 
                                    type='search'
                                    disabled={!isModify} 
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='city' 
                                    name='city'
                                    value={city}
                                    onChange={e => {
                                        setCity(e.target.value);
                                        validator.current.showMessageFor("city");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {isModify && validator.current.message("city", city, "required")}
                                </div>
                            </div>
                        </div> 

                        {/* ZIP CODE */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="zipCode" className='d-block mb-2'> Zip Code <span className='required-color'> * </span> </label>
                            <div className="position-relative">
                                <input 
                                    disabled={!isModify} 
                                    type='search'
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='zipCode' 
                                    name='zipCode'
                                    value={zipCode}
                                    onChange={e => {
                                        setZipCode(e.target.value);
                                        validator.current.showMessageFor("zip code");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {isModify && validator.current.message("zip code", zipCode, "required|numeric|min:5|max:5")}
                                </div>
                            </div>
                        </div> 

                        {/* ADDRESS */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="address" className='d-block mb-2'> Address <span className='required-color'> * </span> </label>
                            <div className="position-relative">
                                <input 
                                    type='search'
                                    disabled={!isModify} 
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='address' 
                                    name='address'
                                    value={address}
                                    onChange={e => {
                                        setAddress(e.target.value);
                                        validator.current.showMessageFor("address");
                                    }}
                                />
                                <div className='input-error-message'>
                                    {isModify && validator.current.message("address", address, "required")}
                                </div>
                            </div>
                        </div> 

                        {/* COMPANY NAME */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="company" className='d-block mb-2'>Company Name</label>
                            <div className="position-relative">
                                <input 
                                    type='search'
                                    disabled={!isModify}
                                    className={`form-input ${!isModify && 'bg-light-skyblue'}`}
                                    id='company' 
                                    name='company'
                                    value={company}
                                    onChange={e => {
                                        setCompany(e.target.value);
                                        if(e.target.value === '') setVat('');
                                    }}
                                />
                            </div>
                        </div>

                        {/* VAT NUMBER */}
                        <div className="mb-4 me-sm-5 w-100 w-max-350">
                            <label htmlFor="vat" className='d-block mb-2'>VAT Number</label>
                            <input 
                                type='search'
                                disabled={!isModify || company === ''}
                                className={`form-input ${(!isModify || company === '') && 'bg-light-skyblue'}`}
                                id='vat' 
                                name='vat'
                                value={vat}
                                onChange={e => {
                                    setVat(e.target.value);
                                    validator.current.showMessageFor("vat");
                                }}
                            />
                            <div className='input-error-message'>
                                {isModify && isEmpty(company) && validator.current.message("vat", vat, "numeric|min:11|max:11") }
                                {isModify && !isEmpty(company) && validator.current.message("vat", vat, "required|numeric|min:11|max:11")  }   
                            </div>
                        </div>  
                    </div>

                    {/* BUTTONS */}
                    <div>
                        {isModify &&
                        <>
                            <button className='custom-btn btn-light-purple me-3 mt-3' type='submit'>Save</button>
                            <span className='custom-btn me-3 mt-3 required-color pointer' onClick={handleCancel} >Cancel</span>
                        </>}
                    </div>

                </form>

                <div> { !isModify && <button className='custom-btn btn-midnight-outline me-3 mt-3' onClick={handleModify}>Modify</button> } </div> 
            </>
        }
    </>
  )
}

export default Profile