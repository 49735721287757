import { useEffect, useContext } from 'react';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReactCardFlip from 'react-card-flip';
import { isEmpty } from 'lodash';
import { Skeleton } from '@mui/material';

import { context } from '../../context/house/context';
import { getHouseQRCode } from '../../services/houseService';
import { clearHouse, getSingleHouse } from '../../redux/actions/singleHouse';
import { clearDocuments, clearFilteredDocuments } from '../../redux/actions/houseDocuments';
import { clearImages } from '../../redux/actions/houseImages';

import Accordion from '../../components/ui/Accordion';
import Modal from '../../components/ui/modal/Modal';
import Pagination from '../../components/ui/Pagination';
import QRCode from '../../components/ui/QRCode';
import { Slider } from '../../components/ui/Slider';
import {houseDetailHeader} from '../../data/constant';
 
const HouseDetail = () => {

  const houseDetailContext = useContext(context);
  const { id, token, dispatch, closeModal, 
    labelRef, handleDownloadLabel, 
    house, attributes, 
    documents, documentsList, filteredDocuments,
    images, activeAddImgModal, setActiveAddImgModal, 
    activeAddDocModal, setActiveAddDocModal, 
    perPage, setPerPage, handlePerPage, 
    currentPage, setCurrentPage, handlePageChange,
    flip, setFlip, 
    qr, setQr, 
    loadSkeleton, setLoadSkeleton,
    showDoc, activeDocModal, handleShowDoc, 
    shareDoc, activeShareModal, handleShareDoc } = houseDetailContext;

  useEffect(() => {
    dispatch(getSingleHouse(id));
    getHouseQRCode(id).then(response => setQr(response));
    setPerPage(5);
    setCurrentPage(1);
    setTimeout(() => {
      setLoadSkeleton(false)
    }, 1000);
  }, []);

  useEffect(() => () => {
    dispatch(clearHouse());
    dispatch(clearDocuments());
    dispatch(clearFilteredDocuments());
    dispatch(clearImages());
  }, []);

  if(isEmpty(token)) return <Navigate to="/login" replace /> ;

  return (
    <>
      {!isEmpty(house) ? (
        <main className='max-container m-auto p-container'>
          <Helmet>
              <title>QR-House | House details</title>
          </Helmet>

          <div className='row m-0 mb-4'>
            {loadSkeleton ? 
              <h4 className='col-12 col-sm-6 my-auto dark-purple bold'>
                  <Skeleton variant='rectangle' sx={{width:'120px', height:'40px'}} className='my-1'/>
              </h4>
            : 
              <h4 className='col-12 col-sm-6 py-2 my-auto dark-purple bold'>House Details</h4>
            }
          </div>
          
          <section className="row m-0">
            <div className="col-12 col-lg-5 position-relative p-0 p-md-3 h-500">
              {house.attributes && isEmpty(house.attributes.image) ?
                <>
                  { loadSkeleton ?
                    <div className='w-100 h-100 overflow-hidden'> 
                      <Skeleton variant='rectangle' width={'100%'} height={'75%'}/>
                      <div className='h-25 d-flex pt-2 justify-content-between'>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                      </div> 
                    </div> 
                  : 
                    <img src='/images/house_xxl.png' alt="image-slide" className='w-100 h-100 cover'/>
                  }
                </>
              :
                <> 
                  { loadSkeleton || isEmpty(images) ? 
                    <div className='w-100 h-100 overflow-hidden'> 
                      <Skeleton variant='rectangle' width={'100%'} height={'75%'}/>
                      <div className='h-25 d-flex pt-2 justify-content-between'>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                        <Skeleton variant='rectangle' width={'24%'} height={'100%'}/>
                      </div>
                    </div>  
                  : 
                    <Slider images={images}/>
                  } 
                </>
              }
            </div>
            <div className="col-12 col-lg-7 my-3 my-md-0 p-md-3 overflow-hidden">
              <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
                <div className='w-100 h-100 position-relative py-5 py-md-0 bg-white'>
                  { loadSkeleton ? 
                    <div className="position-absolute top-0 right-0">
                      <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px'}}/>
                    </div> 
                  :
                    <button className='custom-btn btn-midnight-outline position-absolute top-0 right-0' onClick={() => setFlip(!flip)}>House Details</button>
                  }
                  <div className="row m-0 h-100 d-center bg-white pt-0 pt-md-4">
                    <div className=' col-12 col-sm-11 col-md-9 col-lg-12 col-xl-9 py-4 pt-md-5 px-lg-0'>
                      <div className="round-20 w-100 pb-3 overflow-auto qr-card-shadow"> 
                        <div className="d-flex overflow-auto w-min-500 bg-white" ref={labelRef}>
                          <div className="d-center">
                            { loadSkeleton || isEmpty(qr) ? 
                              <Skeleton variant='rectangle' width={140} height={140} className='m-3'/> 
                            : 
                              <img src={qr.config.url} alt="house-QR-code" className='qube-qr'/>
                            }
                          </div>
                          <div className='px-2 py-3 d-flex align-items-center w-100'>
                            <ul className='list-unstyled m-0 w-100'>
                                {/* Registration ID */}
                                <li className='my-2'>
                                  { loadSkeleton ? 
                                    <>
                                      <Skeleton variant='text'  className='m-0 fs-18 bold w-25'/>
                                      <Skeleton variant='text'  className='m-0 w-50'/>
                                    </>
                                  : 
                                    <>
                                      <h5 className='purple m-0 fs-18 bold'>Registration ID</h5>
                                      <p className='m-0 dark-purple'>{attributes.regid}</p>
                                    </>
                                  }
                                </li>

                                {/* Creation Date */}
                                <li className='my-2'>
                                  { loadSkeleton ? 
                                    <>
                                      <Skeleton variant='text'  className='m-0 fs-18 bold w-25'/>
                                      <Skeleton variant='text'  className='m-0 w-50'/>
                                    </>
                                  : 
                                    <>
                                      <h5 className='purple m-0 fs-18 bold'>Creation Date</h5>
                                      <p className='m-0 dark-purple'>{attributes.regdate}</p>
                                    </>
                                  }
                                </li>

                                {/* Transaction */}
                                <li className='my-2'>
                                  { loadSkeleton ? 
                                    <>
                                      <Skeleton variant='text'  className='m-0 fs-18 bold w-25'/>
                                      <Skeleton variant='text'  className='m-0 w-100'/>
                                    </>
                                  : 
                                    <>
                                      <h5 className='purple m-0 fs-18 bold'>Transaction</h5>
                                      <a href={`https://explorer.quadrans.io/tx/${house.txh}/internal-transactions`} target='_blank' rel='noreferrer'>
                                          <p className='m-0 word-break'>{house.txh}</p>
                                      </a>
                                    </>
                                  }
                                </li>

                                {/* Address */}
                                <li className='my-2'>
                                  { loadSkeleton ? 
                                    <>
                                      <Skeleton variant='text'  className='m-0 fs-18 bold w-25'/>
                                      <Skeleton variant='text'  className='m-0 w-75'/>
                                    </>
                                  : 
                                    <>
                                      <h5 className='purple m-0 fs-18 bold'>Address</h5>
                                      <p className='m-0 dark-purple'>{attributes.address} {attributes.geo_zip}, {attributes.geo_region}, {attributes.geo_state}</p>
                                    </>
                                  }
                                </li>
                            </ul>
                          </div>
                        </div>
                        <div className='overflow-hidden round-20 w-min-500'>
                          <div className="d-flex justify-content-start justify-content-sm-center align-items-center round-20 p-2">
                            { loadSkeleton ?
                              <>
                                <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px', marginRight:'1rem'}} />
                                <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px', marginRight:'1rem'}} />
                                <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px', marginRight:'1rem'}} />
                              </>
                            : 
                              <>
                                <QRCode qr={qr ? qr.data : null}/>
                                <button className='d-center custom-btn btn-light-purple mx-3' onClick={handleDownloadLabel}>Download Label</button>
                                <Link to={`/house-information/${id}`} target="_blank" className='text-decoration-none'>
                                  <button className='d-center custom-btn btn-light-purple'>House Details</button>
                                </Link>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='w-100 h-100 d-flex flex-column justify-content-between position-relative py-5 py-md-0 bg-white'>
                  <button className='custom-btn btn-midnight-outline position-absolute top-0 right-0' onClick={() => setFlip(!flip)}>Show QR</button>
                  <div className=''>
                    <h4 className='purple bold fs-18'>Registration ID</h4>
                    <p className='dark-purple bold fs-16'> {attributes.regid} </p>
                  </div>

                  <div className=''>
                    <h4 className='purple bold fs-18'>Creation Date</h4>
                    {/* <p className='dark-purple bold fs-16'> {format(new Date(attributes.regdate), 'dd/MM/yyyy')} </p> */}
                    <p className='dark-purple bold fs-16'> {attributes.regdate} </p>
                  </div>

                  <div className='d-flex flex-wrap'>
                    {/* STATE */}
                    <div className='me-5'>
                      <h5 className='purple bold fs-18'>State</h5>
                      <p className='dark-purple bold fs-16'> {attributes.geo_state} </p>
                    </div>

                    {/* REGION */}
                    <div className='me-5'>
                      <h5 className='purple bold fs-18'>Region</h5>
                      <p className='dark-purple bold fs-16'> {attributes.geo_region} </p>
                    </div>

                    {/* ZIP CODE */}
                    <div className='me-5'>
                      <h5 className='purple bold fs-18'>Zip code</h5>
                      <p className='dark-purple bold fs-16'> {attributes.geo_zip} </p>
                    </div>

                    {/* ADDRESS */}
                    <div className='me-5'>
                      <h5 className='purple bold fs-18'>Address</h5>
                      <p className='dark-purple bold fs-16'> {attributes.address} </p>
                    </div>
                  </div>

                  <div className=''>
                    <h4 className='purple bold fs-18'>Owner Blockchanin Address</h4>
                    {/* {attributes.owner.map((own, index) => (
                      <p key={index} className='dark-purple bold fs-16'>{own}</p>
                    ))} */}
                    <p className='dark-purple bold fs-16 word-break'>{attributes.owner}</p>
                  </div>

                  <div className=''>
                    <h4 className='purple bold fs-18'>TxID of Registration</h4>
                    <p className='dark-purple bold fs-16 m-0 word-break'>{house.txh}</p>
                    {/* <p className='dark-purple bold fs-16 m-0'>0xb4a5f1bc4a3746220525d7401e277e5ac763b87de0ca73b8ffece0052d52e2ec</p> */}
                  </div>
                </div>
              </ReactCardFlip>    
            </div>
          </section>

          <section className="row m-0 mt-5">
            <div className="col-12 col-lg-3 col-xl-3">
              { loadSkeleton ? 
                <h4 className='my-auto dark-purple bold mb-4' style={{padding:'2px 0'}}>
                    <Skeleton variant='rectangle' sx={{width:'120px', height:'40px'}} className='p-2'/>
                </h4>
              : 
                <h4 className=' py-2 my-auto dark-purple bold mb-4'>Categories</h4>
              }
              <div className="py-1 row">
                <Accordion loading={loadSkeleton} documents={documents} setCurrentPage={setCurrentPage}/>
              </div>
            </div>
                
            <div className="col-12 col-lg-9 col-xl-9 ps-lg-5">
              
              <div className='row mb-4'>
                { loadSkeleton ? 
                  <h4 className='col-12 col-sm-5 col-md-6 my-auto dark-purple bold px-3' style={{padding:'2px 0'}}>
                      <Skeleton variant='rectangle' sx={{width:'120px', height:'40px'}} className='p-2'/>
                  </h4>
                : 
                  <h4 className='col-12 col-sm-5 col-md-6 py-2 my-auto dark-purple bold'>Documents</h4>
                }

                <div className='col-12 col-sm-7 col-md-6'>
                  <div className='d-flex justify-content-end overflow-auto'>

                    {loadSkeleton ? 
                      <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px', marginRight:'1rem'}} />
                      :
                      <button className='custom-btn btn-midnight-fill me-3' onClick={() => setActiveAddImgModal(true)}>Add image</button>
                    }

                    {loadSkeleton ? 
                      <Skeleton variant='circle' sx={{width:'140px', height:'40px', borderRadius:'50px'}} />
                      :
                      <button className='custom-btn btn-milky-fill' onClick={() => setActiveAddDocModal(true)}>Add document</button>
                    }
                    
                  </div>
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-12 p-0 overflow-hidden'>
                  <div className='t-head mb-2 p-1'>   
                    <div className='px-2'>
                      {loadSkeleton ? 
                        <Skeleton variant='rectangle' height={44} className='rounded m-0'/> 
                      :
                        <ul className='house-header mb-0'>
                          {houseDetailHeader.map( (header, index) => {
                              // <li key={index} className='house-header-content px-3' style={{width:header.width}}>
                              //     {header.name}
                              // </li>

                              const item = index === 0 ? 
                            <li key={index} className='house-header-content px-1' style={{maxWidth:header.width, width:header.width}}>
                                {header.name}
                            </li> :
                            <li key={index} className='house-header-content px-3' style={{width:header.width}}>
                                {header.name}
                            </li>
                            return item;
                          })}
                        </ul>
                      }
                    </div>

                    <div className='house-list-wrapper p-2'>
                      {house.attributes && isEmpty(house.attributes.doc) ?
                        <></>
                      :
                        <> 
                          {loadSkeleton === true || isEmpty(documents) ?                           
                            <>
                              {house.attributes.doc.map((d, index) => (
                                  <Skeleton  key={index} height={110} sx={{ my:-2 }} />
                              ))}
                            </>
                          : 
                            <>
                              {documentsList.map((document, index) => (
                                <ul key={index} className='house-list-item'>
                                  <li className='house-content px-3' style={{maxWidth:'67px', backgroundColor:'#efb994'}}>
                                    <img src="/images/info.png" alt="QR-house" />
                                  </li>
                                  <li className='house-content word-break ps-4 pe-3 olive justify-content-center' style={{width:'180px'}}>
                                    {/* <span>{document.data.name.replace(/\.[^/.]+$/, "")}</span> */}
                                    <span>{document.data.name}</span>
                                  </li>
                                  <li className='house-content word-break px-3' style={{width:'180px'}}>
                                    <span>{document.data.description}</span>
                                  </li>
                                  <li className='house-content word-break px-3' style={{width:'180px'}}>
                                    <span>{document.data.type}</span>
                                  </li>
                                  <li className='house-content px-3' style={{width:'180px'}}>
                                    <a  className='round-50' href={document.data.url} target='_blank' rel='noreferrer' download>
                                      <button className='custom-btn btn-light-purple'>Download</button>
                                    </a>
                                  </li> 

                                  <li className='house-content px-3' style={{width:'90px'}}>
                                    <img className='pointer' src="/images/info-icon.png" alt="information" onClick={() => handleShowDoc(document)} />
                                  </li>
                                  
                                  
                                  <li className='house-content px-3' style={{width:'90px'}}>
                                    <i className="fa fa-share-alt fa-lg p-2 pointer" aria-hidden="true" onClick={() => handleShareDoc(document)}></i>
                                  </li>

                                </ul> 
                              ))}
                            </>
                          } 
                        </>
                      } 
                    </div>
                  </div>
                </div>
              </div>                

            </div>
          </section>

          <section className="row mt-3 flex-column-reverse flex-lg-row m-0">
            <div className="col-12 col-lg-3 col-xl-3">
              {loadSkeleton ?
                <Skeleton variant='rectangle' sx={{maxWidth:'150px', height:'40px'}}/>
              :
              <div className='d-flex align-items-center' style={{maxWidth:'150px'}} >
                  <label className="me-3 text-nowrap">Show</label>
                  <select className="form-select" aria-label="select number of rows" value={perPage} onChange={handlePerPage} >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                  </select>
              </div> 
              }
            </div>
            <div className='col-12 col-lg-9 col-xl-9 ps-lg-5 d-flex justify-content-center overflow-hidden mb-4 mb-lg-0'>
              <Pagination totalHouses={filteredDocuments.length} currentPage={currentPage} perPage={perPage} onPageChange={handlePageChange} />
            </div>
          </section>

          <Modal 
            activeDocModal={activeDocModal} document={showDoc} 
            activeShareModal={activeShareModal} shareDoc={shareDoc}
            activeAddDocModal= {activeAddDocModal}
            activeAddImgModal= {activeAddImgModal}
            // activeSliderModal= {activeSliderModal} activeImage={activeImage}
            closeModal={closeModal}
          />
        </main>
      ) : null}
    </>
  )
}

export default HouseDetail;