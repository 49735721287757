import http from './httpService';
import config from './config.json';

export const getHouseInformation = quid => http.get(`${config.qrHouseApi}/readquid/${quid}`);

export const getHouseDocument = docId => http.get(`${config.qrHouseApi}/docs/${docId}`);

export const newHouse = house => http.post(`${config.qrHouseApi}/quid/`, house);

export const getHouseQRCode = quid => http.get(`${config.qrHouseApi}/util/qr/${quid}`); 

export const getHouseImages = imgId => http.get(`${config.qrHouseApi}/img/${imgId}`); 

export const newDocs = docs => http.post(`${config.qrHouseApi}/quid/`, docs);

export const newImgs = imgs => http.post(`${config.qrHouseApi}/quid/`, imgs);

export const getHouseTx = quid => http.get(`${config.qrHouseApi}/quid/hist/${quid}`);

export const getPayment = paymentId => http.get(`${config.qrHouseApi}/create-checkout-session/?product_id=${paymentId}`);

export const getPaymentList = () => http.get(`${config.qrHouseApi}/payments/list`);

export const getPaymentStatus = sessionId => http.get(`${config.qrHouseApi}/payments/session/check/?session_id=${sessionId}`);
