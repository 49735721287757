import React from 'react';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom';
import { isEmpty } from 'lodash';

const Header = () => {

    // const [showProfile, setShowProfile] = useState(false);
    // const { width, height } = useWindowDimensions();
    // const handleShowProfile = () => {     
    //     if(width < '768') setShowProfile(!showProfile);
    // }

    const user = useSelector(state => state.user);

    // const [isAuthorized, setAuthorized] = useState(false);
    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    //     if(token && !isExpired(token)) {
    //         setAuthorized(true);
    //     } else {
    //         setAuthorized(false);
    //     }
    // }, [])

    return (  
        <header className="bg-purple white" >
            <section className="header max-container p-0">
                <div className="row w-100 m-0">
                    <div className="col-6 d-flex align-items-center">
                        <Link to={isEmpty(user) ? '/' : '/houses'} className='d-inline-flex'>
                            <img src="/images/qr-house-white@2x.png" alt="QR-House logo" className='w-100 w-logo-max'/>
                        </Link>
                    </div>
                    {!isEmpty(user) ? <div className="col-6 d-flex flex-column flex-md-row justify-content-end align-items-center m-auto py-1">
                        <div className="avatar-wrapper me-md-2 mb-1 mb-md-0 align-self-end">
                            <img className="w-100 h-100" src="/images/avatar.png" alt="QR_Avatar" />
                        </div>
                        <div className='align-self-end'>
                            <span className="d-none d-md-block">{user.user_email}</span>
                            {/* <Link to="/logout" className="form-link italic me-3">Logout</Link> */}
                            <Link to="/dashboard" className="form-link italic">Settings</Link>
                        </div>
                    </div> : null}
                </div>
            </section>
        </header>
    );
}
 
export default Header;