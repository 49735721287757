import { format } from 'date-fns';
import {filter, includes, every, lowerCase, gte, lte, isEmpty} from 'lodash';

export const houseFilter = (houses, filteredInput) => {
    const {ownerFilter, houseIdFilter, startDateFilter, endDateFilter, regionFilter, zipCodeFilter, addressFilter, countryFilter} = filteredInput;
    const filteredHouses = filter(houses, house => every([
        includes(lowerCase(house.attributes.owner), lowerCase(ownerFilter)),
        includes(lowerCase(house.attributes.regid), lowerCase(houseIdFilter)), 
        includes(lowerCase(house.attributes.geo_region), lowerCase(regionFilter)),
        includes(lowerCase(house.attributes.geo_zip), lowerCase(zipCodeFilter)),
        includes(lowerCase(house.attributes.address), lowerCase(addressFilter)),
        includes(lowerCase(house.attributes.geo_state), lowerCase(countryFilter)),

        startDateFilter !== 0 ? (gte(new Date(house.attributes.regdate).getTime() / 1000, startDateFilter)) : true,
        endDateFilter !== 0 ? (lte(new Date(house.attributes.regdate).getTime() / 1000, endDateFilter)) : true,
    ]));
    return filteredHouses;
}

export const documentFilter = (docs, checkedItem) => {

    // console.log(checkedItem.length);
    if(checkedItem.length > 0) {
        const filteredDocuments = filter(docs, doc => every([
            includes(lowerCase(checkedItem), lowerCase(doc.data.type))
        ]));
        // console.log({filteredDocuments});
        return filteredDocuments;   
    } else {
        return docs;
    }   
}