import { useCallback, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toPng } from 'html-to-image';

import { context } from './context';
import { paginate } from '../../utils/paginate';

const HouseContext = ({children}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const labelRef = useRef(null);
  const {id} = useParams();

  const userInfo = useSelector(state => state.userInfo);
  const house = useSelector(state => state.house);
  const houses = useSelector(state => state.houses);
  const filteredHouses = useSelector(state => state.filterHouses);
  const documents = useSelector(state => state.houseDocuments);
  const filteredDocuments = useSelector(state => state.filterHouseDocuments);
  const images = useSelector(state => state.houseImages);

  const {attributes} = house;
  const token = localStorage.getItem("token");

  const [activeHomeModal, setActiveHomeModal] = useState(false);
  const [activePaymentModal, setActivePaymentModal] = useState(false);
  const [activeAddDocModal, setActiveAddDocModal] = useState(false);
  const [activeAddImgModal, setActiveAddImgModal] = useState(false);
  const [activeDocModal, setActiveDocModal] = useState(false);
  const [activeShareModal, setActiveShareModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [flip, setFlip] = useState(false);
  const [loadSkeleton, setLoadSkeleton] = useState(true);
  const [qr, setQr] = useState('');
  const [tx, setTx] = useState('');
  const [addhouseId, setAddhouseId] = useState('');
  const [shareDoc, setShareDoc] = useState('');
  const [showDoc, setShowDoc] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  
  const housesList = paginate(filteredHouses, currentPage, perPage);
  const documentsList = paginate(filteredDocuments, currentPage, perPage);

  const handlePerPage = e => {
      setPerPage(e.target.value);
      setCurrentPage(1);
  }
  const handlePageChange = page => {
    setCurrentPage(page);
  }
  const closeModal = () => {
    setActiveAddDocModal(false);
    setActiveAddImgModal(false);
    setActiveDocModal(false);
    setActiveShareModal(false);
  }
  const closeHousesModal = () => {
    setActiveHomeModal(false);
    setActivePaymentModal(false);
    navigate('/houses');
  }
  const handleShowDoc = document => {
    setActiveDocModal(true);
    setShowDoc(document);
  }
  const handleShareDoc = document => {
    setActiveShareModal(true);
    setShareDoc(document.data.url);
  }
  const handleDownloadLabel = useCallback(() => {
    if (labelRef.current === null) {
      return
    }
    toPng(labelRef.current, { cacheBust: true, })
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'House-label.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [labelRef]);

  return (
    <context.Provider value={{ 
      id, token, navigate, dispatch, location, 
      userInfo, attributes, images, 
      house, housesList, filteredHouses, houses, 
      documents, filteredDocuments, documentsList,
      closeModal, closeHousesModal,
      activeHomeModal, setActiveHomeModal, 
      activePaymentModal, setActivePaymentModal, 
      activeAddDocModal, setActiveAddDocModal, 
      activeAddImgModal, setActiveAddImgModal, 
      activeDocModal,
      activeShareModal,  setActiveShareModal, 
      addhouseId, setAddhouseId, 
      loadSkeleton, setLoadSkeleton,
      perPage, setPerPage, handlePerPage,
      currentPage, setCurrentPage, handlePageChange,
      showFilter, setShowFilter, 
      flip, setFlip, 
      tx, setTx, 
      qr, setQr, 
      labelRef, handleDownloadLabel, 
      shareDoc, setShareDoc, handleShareDoc,
      showDoc, handleShowDoc,     
    }}>
        {children}
    </context.Provider>
  )
}

export default HouseContext;