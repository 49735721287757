import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import Button from '../../components/ui/Button';

const Page404 = () => {
  return (

    // <div className='notfound-404-wrapper'>
    //   <div className='notfound-404' >
    //     <h1>Oops!</h1>
    //     <h2>404 - Page not found</h2>
    //     <p className='w-75 m-auto mb-4'>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
    //     <Link to="/" className='link border'> Go To Homepage </Link>

    //     <Button text='Go To Homepage' bg='milky-fill'/>
    //   </div>
    // </div>

    <div className='main-layout'>
      <Helmet> <title>QR-House | Page not found</title> </Helmet>
      <Header/>
      <main className='mainLayout-main d-center'>
          <div className='max-container text-center p-2 p-md-5' >    
                <img src="/images/notFound-404.png" alt="page not found" className='w-100' style={{maxWidth:'488px'}}/>  
                <h3 className='mt-5 purple bold text-uppercase'>SOMETHING’S MISSING</h3>    
                <p className='dark-purple'>Page not found</p>
                <Link to="/" className='d-inline-block round-50'> <Button text='Go To Homepage' bg='milky-fill'/> </Link>  
          </div>
      </main>
      <Footer/>
    </div> 
  )
}

export default Page404