export const housesReducer = (state = [], action) => {
    switch(action.type) {
        case "GET_HOUSES":
            return [...action.payload];

        case "ADD_HOUSE":
            return [...action.payload];
            
        case "CLEAR_HOUSES":
            return [...action.payload];

        default:
            return state;
    }
}