import { Link, Navigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import Button from '../../components/ui/Button';

const RegisterConfirmation = () => {

  const token = localStorage.getItem("token");
  if(!isEmpty(token)) return <Navigate to="/houses" replace /> ;

  return (
    <div className='h-100 d-center'>
      <div className='w-100 mw-500 zIndex-1' style={{margin:'50px'}}>
        <img src="/images/reg-confirm@2x.png" alt="confirmation" />
        <h2 className='fs-30 my-3'>Welcome on board!</h2>
        <p className='fs-18 mb-0'>The registration was successful.</p>
        <p className='fs-18 mb-4'>Please check your inbox to complete and confirm your site registration</p>
        <div className="text-start">
          <Link to='/login' className='d-inline-block round-50'> <Button text='Login' bg='milky-fill' /> </Link>
        </div>
      </div>
    </div>
  )
}

export default RegisterConfirmation;