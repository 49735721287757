const DocumentInfoDialog = ({document, closeModal}) => {
    return (
        <div className='customModal'> 
            <div className='h-100 vw-100 overflow-auto'>
                <div className='modal-box'>
                    <div className="row pt-1 pb-3 mb-4 border-bottom-light-gray">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <h4 className='py-2 my-auto dark-purple bold'>Document details</h4>
                            <i className="fa fa-times fa-lg pointer p-2" aria-hidden="true" onClick={closeModal}></i>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 py-2">
                            <span className='me-2 bold d-inline-block'>Name: </span>
                            <strong className='dark-purple word-break'>{document.data.name}</strong>
                        </div>
                        <div className="col-12 py-2">
                            <span className='me-2 bold d-inline-block'>Uploader: </span>
                            <strong className='dark-purple word-break'>{document.data.uploader}</strong>
                        </div>
                        <div className="col-12 py-2">
                            <span className='me-2 bold d-inline-block'>Transaction: </span>
                            <a href={`https://explorer.quadrans.io/tx/${document.data.txh}/internal-transactions`} target='_blank' rel='noreferrer'>
                                <strong className=' word-break'>{document.data.txh}</strong>
                            </a>
                        </div>
                        <div className="col-12 py-2">
                            <span className='me-2 bold d-inline-block'>Description: </span>
                            <strong className='dark-purple word-break'>{document.data.description}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentInfoDialog;