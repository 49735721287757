import React from 'react'

const QRCode = ({qr}) => {

    var downloadImageFromUri = function(imgURI) {
        var evt = new MouseEvent('click', {
          view: window,
          bubbles: false,
          cancelable: true
        });
      
        var a = document.createElement('a');
        a.setAttribute('download', 'QR_CODE.png');
        a.setAttribute('href', imgURI);
        a.setAttribute('target', '_blank');
      
        a.dispatchEvent(evt);
      }
       
      var svgClickHandler = function () {
      
        //retrieve image element
        var svg_el   = document.querySelector("#svg_wrapper").querySelector("svg"); // or other selector like querySelector()
        var rect = svg_el.getBoundingClientRect(); // get the bounding rectangle
      
        //create canvas element
        var canvas = document.createElement('canvas');
      
        //set size
        canvas.width = rect.width;
        canvas.height = rect.height;
      
        var ctx = canvas.getContext('2d');
      
        //serialize
        var data = (new XMLSerializer()).serializeToString(svg_el);
        var DOMURL = window.URL || window.webkitURL || window;
      
        var img = new Image();
        var svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'});
        var url = DOMURL.createObjectURL(svgBlob);
        img.src = url;
      
        //set
        img.onload = function () {
          ctx.drawImage(img, 0, 0);
          DOMURL.revokeObjectURL(url);
      
          var imgURI = canvas
              .toDataURL('image/png')
              .replace('image/png', 'image/octet-stream');
      
          downloadImageFromUri(imgURI);
        };
      
      
      };
      

  return (
    // <div className='w-100 h-100 border' id='svg_wrapper' dangerouslySetInnerHTML={{__html: qr}} className='' onClick={svgClickHandler} />
    <button className='d-center custom-btn btn-light-purple' onClick={svgClickHandler}>Download QR
      <div className='overflow-hidden' style={{width:0, height:0}}>
        <div className='w-100 h-100 border' id='svg_wrapper' dangerouslySetInnerHTML={{__html: qr}} className=''  />
      </div>
    </button>
  )
}

export default QRCode