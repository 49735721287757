export const addNewImgs = imgs => {
    return async (dispatch, getState) => {
        await dispatch({type:"ADD_IMAGES", payload: [...getState().images, imgs]})
    }
}

export const clearImages = () => {
    return async dispatch => {
        await dispatch({type:"CLEAR_IMAGES", payload: []});
    }
}