import http from './httpService';
import config from './config.json';

export const registerUser = user => http.post(`${config.qrHouseApi}/user/create/`, JSON.stringify(user));
 
export const loginUser = user => http.post(`${config.qrHouseApi}/user/login/`, JSON.stringify(user));

export const getUserInformation = (user_quid) => http.get(`${config.qrHouseApi}/user/${user_quid}`); 

export const getLookUpRoles = () => http.get(`${config.qrHouseApi}/util/lookup/roles/?distinct=true`); 

export const getCountries = () => http.get(`${config.qrHouseApi}/util/lookup/countries/`); 

export const getRegions = () => http.get(`${config.qrHouseApi}/util/lookup/regions/`); 

export const getDocumentTypes = () => http.get(`${config.qrHouseApi}/util/lookup/documents/`); 

export const getPK = () => http.get(`${config.qrHouseApi}/config`); 

export const changePassword = new_pass => http.post(`${config.qrHouseApi}/user/changepassword/`, JSON.stringify(new_pass)); 

export const resetPassword = () => http.get(`${config.qrHouseApi}/user/requestpasswordreset/`); 

export const changeProfile = new_profile => http.post(`${config.qrHouseApi}/user/updatedetails/`, JSON.stringify(new_profile)); 