import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { format } from 'date-fns'
import { loadStripe } from "@stripe/stripe-js";
import SimpleReactValidator from 'simple-react-validator';

import { addNewFilterHouse, addNewHouse } from '../../../redux/actions/houses';
import { addNewImgs } from '../../../redux/actions/houseImages';
import { getHouseDocument, getHouseInformation, getPayment, newDocs, newHouse, newImgs } from '../../../services/houseService';
import { errorMessage, successMessage } from '../../../utils/message';
import { readUploadedFile} from '../../../utils/getBase64';

import PaymentDialog from './PaymentDialog';
import AddHouseDialog from './AddHouseDialog';
import AddDocumentDialog from './AddDocumentDialog';
import AddImageDialog from './AddImageDialog';
import DocumentInfoDialog from './DocumentInfoDialog';
import ImageDialog from './ImageDialog';
import DocumentShareDialog from './DocumentShareDialog';
import { select_style } from '../SelectStyle';
import homePage from '../../../data/homePage.json';

const options = [
    { value: 'Floor plans', label: 'Floor plans' },
    { value: 'Provenance deeds', label: 'Provenance deeds' },
    { label: "Mortgages and Financing", value: "Mortgages and Financing" },
    { label: "Changes to the property with respect to purchase", value: "Changes to the property with respect to purchase" },
    { value: 'Cadastral identification', label: 'Cadastral identification' },
    {
      label: "Facilities",
      options: [
        { label: "Facilities > Electrical", value: "Electrical" },
        { label: "Facilities > Heating", value: "Heating" },
        { label: "Facilities > Cooling", value: "Cooling" },
        { label: "Facilities > Plumber", value: "Plumber" },
        { label: "Facilities > Swimming Pool", value: "Swimming Pool" },
        { label: "Facilities > Others", value: "Others" }
      ]
    },
    { value: 'Other deeds', label: 'Other deeds' },
  ];

const Modal = ({addhouseId, shareDoc, activeShareModal, activeSliderModal, activeImage, document,
    activePaymentModal, activeHomeModal, activeAddDocModal, activeAddImgModal, activeDocModal, closeModal}) => {

    const dispatch = useDispatch();
    let btnRef = useRef();

    const {user} = useSelector(state => state.userInfo);
    const countries = useSelector(state => state.countries);
    const regions = useSelector(state => state.regions);
    const {publicKey} = useSelector(state => state.publicKey);
    const {quid , attributes} = useSelector(state => state.house);
    const houseImages = useSelector(state => state.houseImages);

    const { offerta } = homePage;

    const countriesName=[];
    const regionsName=[];
    countries.map(country => countriesName.push({value: country.name, label: country.name}));
    regions.map(region => regionsName.push({value: region.name, label: region.name}));

    const [registrationId, setRegistrationId] = useState("");
    const [houseName, setHouseName] = useState("");
    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [address, setAddress] = useState("");
    const [date, setDate] = useState(format(new Date(), "yyyy/MM/dd"));
    const [documents, setDocuments] = useState([]);
    const [images, setImages] = useState([]);
    const [encodedImages, setEncodedImages] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [isCopyLink, setCopyLink] = useState(false);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState('2');
    const [, forceUpdate] = useState();

    const validator = useRef(new SimpleReactValidator({
        element: message => <div style={{color:'#ef5350'}}>{message}</div>
    }));

    const handleCloseModal = () => {
        closeModal();
        reset();
    }
    const reset = () => {
        // setRegistrationId("");
        // setHouseName("");
        // setCountry("");
        // setRegion("");
        // setCity("");
        // setZipCode("");
        // setAddress("");
        // setDate(format(new Date(), "yyyy/MM/dd"));
        setDocuments([]);
        setImages([]);
        setEncodedImages([]);
    }
    const handlePaymentSubmit = async event => {
        event.preventDefault();
        setBtnLoading(true);
        if(btnRef.current){
            btnRef.current.setAttribute("disabled", "disabled");
        }
        try {
            const stripePromise = loadStripe(publicKey);
            const stripe = await stripePromise;
            const {data, status} = await getPayment(selectedPayment);

            if(status === 200) {     
                stripe.redirectToCheckout({sessionId: data.sessionId})
                // .then(res => console.log(res));
                setBtnLoading(false);
                closeModal();
            } 
        } catch(ex){
            // console.log(ex);
            setBtnLoading(false);
        }
    }
    const handleHouseSubmit = async event => {
        event.preventDefault();

        const newHouseData = {
            payment_id: addhouseId,
            regid: registrationId,
            house_name: houseName,
            geo_state: country,
            geo_region: region,
            geo_city: city,
            geo_zip: zipCode,
            address: address,
            regdate: date,
            owner: user,
            doc: documents,
            image: encodedImages
        }

        const docsType = [];
        documents.map(document => docsType.push(document.type)); 
      
        try {
            if(validator.current.allValid() && !docsType.includes('')) {
                setBtnLoading(true);
                if(btnRef.current){
                    btnRef.current.setAttribute("disabled", "disabled");
                }
                const {data, status} = await newHouse(newHouseData);

                if(status === 200) {
                    successMessage("House has been successfully added");
                    setBtnLoading(false);
                    localStorage.removeItem("session");
                    const newHouseAdded = await getHouseInformation(data.quid);

                    if(newHouseAdded.status === 200) {
                        dispatch(addNewHouse(newHouseAdded.data));
                        dispatch(addNewFilterHouse(newHouseAdded.data));
                    }
                    closeModal();
                    reset();
                }
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }
        } catch(ex){
            const {status} = ex.response;
            if (status === 409) {
                // errorMessage("Number of uploaded documents is more than this house's payement plan.");
                errorMessage("Maximum number of document reached for this house's payement plan.");
            }
            else {
                errorMessage("Adding House has been failed.");
            }
            setBtnLoading(false);
            closeModal();
            reset();
        }
    } 
    const handleDocumentSubmit = async event => { 
        event.preventDefault(); 
        try {
            if(!isEmpty(documents)) {
                const docsType = [];
                documents.map(document => docsType.push(document.type)); 

                if(validator.current.allValid() && !docsType.includes('')) {
                    const newDocuments = { doc: documents, quid, owner: attributes.owner[0] }
                    // console.log(newDocuments);
                    // setLoading(true);
                    setBtnLoading(true);
                    if(btnRef.current){
                        btnRef.current.setAttribute("disabled", "disabled");
                    }
                    const {data, status} = await newDocs(newDocuments);

                    if(status === 200) {
                        successMessage("documents have been successfully added");
                        console.log(data)

                        const newDocumentsAdded = await getHouseInformation(data.quid);
                        if(newDocumentsAdded.status === 200) {
                            console.log(newDocumentsAdded.data)
                            const promise = [];
                            newDocumentsAdded.data.attributes.doc.map(document => promise.push(getHouseDocument(document)));
                            Promise.all(promise).then(result => {
                                const documents =[];
                                result.map(res => documents.push(res.data));
                                dispatch({type: "GET_DOCUMENTS", payload: documents});
                                dispatch({type: "GET_DOCUMENTS_FILTER", payload: documents});
                                console.log('done')
                            });
                        }
                        setBtnLoading(false);
                        closeModal();
                        reset();
                    }
                } else {
                    validator.current.showMessages();
                    forceUpdate(1);
                }  
            } else {
                errorMessage("No document uploaded.");
                closeModal();
                reset();
            }
        }catch(ex){
            const {status} = ex.response;
            if (status === 409) {
                errorMessage("Maximum number of documents reached for this house.");
            }
            else {
                errorMessage("Adding documents has been failed.");
            }
            setBtnLoading(false);
            closeModal();
            reset();
        }
    }
    const handleImageSubmit = async event => { 
        event.preventDefault(); 
        // console.log(encodedImages);
        try {
            if(!isEmpty(encodedImages)) {
                const newImages = { image: encodedImages, quid, owner: attributes.owner[0] }

                setBtnLoading(true);
                if(btnRef.current){
                    btnRef.current.setAttribute("disabled", "disabled");
                }
                const {data, status} = await newImgs(newImages);

                if(status === 200) {
                    successMessage("Images have been added successfully.");
                    
                    // console.log(data)
                    window.location.reload();
                    // const newDocumentsAdded = await getHouseDocument(data.data);

                    // if(newDocumentsAdded.status === 200) {

                    //     // dispatch(addNewDocs(newDocumentsAdded.data));
                    //     // dispatch(addNewFilterHouse(newHouseAdded.data));
                    // }
                    setBtnLoading(false);
                    dispatch(addNewImgs(data));
                    closeModal();
                    reset();
                }
            } else {
                errorMessage("No image uploaded.");
                closeModal();
                reset();
            }
        } catch(ex){
            errorMessage("Adding images has been failed.");
            setBtnLoading(false);
            closeModal();
            reset();
        }
    }
    const handleUploadImage = e => { 
        const uploadedImages = Array.from(e.target.files);
        const imagesState = [...images];
        const encodedImagesState = [...encodedImages];

        const newImages = [];
        const promises = [];

        uploadedImages.map(uploadedImage => {
            const preventDuplicateImage = imagesState.filter(image => image.name === uploadedImage.name);
            if(preventDuplicateImage.length === 0) {
                promises.push(readUploadedFile(uploadedImage));
                newImages.push(uploadedImage);
            }
        })

        Promise.all(promises).then((results) => {
            // const res = [];
            // results.map(result => res.push(result.binary));
            // setEncodedImages([...encodedImagesState, ...res]);
            setEncodedImages([...encodedImagesState, ...results]);
        });   
        setImages([...imagesState, ...newImages]);
    }
    const handleDeleteImage = index => {
        const imgArray = [...images];
        const imgEncodedArray = [...encodedImages];

        const filteredImages = imgArray.filter((img, imgIndex) => imgIndex !== index);
        setImages(filteredImages);

        const filteredEncodedImages = imgEncodedArray.filter((img, imgIndex) => imgIndex !== index);
        setEncodedImages(filteredEncodedImages);
    }
    const handleUploadDocument = e => {
        const uploadedDocument = Array.from(e.target.files);
        const documentsState = [...documents];
        const preventDuplicateDocument = documentsState.filter(document => document.name === uploadedDocument && uploadedDocument[0].name);
        if(preventDuplicateDocument.length === 0) readUploadedFile(uploadedDocument[0]).then(res => setDocuments([...documentsState, {binary: res.binary, name: res.name, mimeType: res.mimeType, type:'', description:''}]));
    }
    const handleDeleteDocument = (index) => {
        const docArray = [...documents];
        const filteredDocuments = docArray.filter((doc, docIndex) => docIndex !== index)
        console.log(filteredDocuments)
        setDocuments(filteredDocuments);   
    }

  return (
    <>   

        {/* ADD PAYMENT DIALOG */}
        { activePaymentModal && <PaymentDialog 
            paymentSubmit={handlePaymentSubmit} 
            closeModal={closeModal} 
            selectedPayment={selectedPayment}
            setSelectedPayment={setSelectedPayment}
            offerta={offerta}
            btnLoading={btnLoading} 
            btnRef={btnRef}
        /> }
        
        {/* ADD HOUSE DIALOG */}
        { activeHomeModal && <AddHouseDialog 
            addHouseSubmit={handleHouseSubmit} 
            closeModal={closeModal} 
            handleDeleteImage={handleDeleteImage} 
            handleUploadImage={handleUploadImage} 
            handleDeleteDocument={handleDeleteDocument} 
            handleUploadDocument={handleUploadDocument}
            options={options} 
            selectStyle={select_style} validator = {validator} user={user}
            countriesName={countriesName} regionsName={regionsName}
            registrationId={registrationId} setRegistrationId={setRegistrationId}
            houseName={houseName} setHouseName={setHouseName}
            country={country} setCountry={setCountry}
            region={region} setRegion={setRegion}
            city={city} setCity={setCity}
            zipCode={zipCode} setZipCode={setZipCode}
            address={address} setAddress={setAddress}
            date={date} setDate={setDate}
            documents={documents} setDocuments={setDocuments} 
            images={images}
            btnLoading={btnLoading} 
            btnRef={btnRef}
        />}

        {/* ADD DOCUMENT DIALOG */}
        {activeAddDocModal && <AddDocumentDialog
            addDocumentSubmit={handleDocumentSubmit}
            closeModal={handleCloseModal}
            deleteDocument={handleDeleteDocument}
            uploadDocument = {handleUploadDocument}
            documents={documents} setDocuments={setDocuments} 
            selectStyle={select_style}
            validator = {validator}
            options={options} 
            btnLoading={btnLoading} 
            btnRef={btnRef}
        />}
        
        {/* ADD IMAGE DIALOG */}
        {activeAddImgModal && <AddImageDialog  
            imageSubmit={handleImageSubmit}
            closeModal={handleCloseModal}
            deleteImage={handleDeleteImage}
            uploadImage={handleUploadImage}
            images={images}
            btnLoading={btnLoading} 
            btnRef={btnRef}
        />}

        {/* DOCUMENT DIALOG */}
        {activeDocModal && <DocumentInfoDialog
            closeModal={closeModal}
            document={document}        
        />}

        {/* DOCUMENT SHARE DIALOG */}
        {activeShareModal && <DocumentShareDialog
            closeModal={closeModal}
            isCopyLink={isCopyLink}
            setCopyLink={setCopyLink}
            shareDoc={shareDoc}

        />}

        {/* IMAGE DIALOG */}
        {activeSliderModal && <ImageDialog
            thumbsSwiper={thumbsSwiper}
            setThumbsSwiper={setThumbsSwiper}
            closeModal={closeModal}
            houseImages={houseImages}
            activeImage={activeImage}
        />}

    </>
  )
}

export default Modal;