// import { getHouseInformation } from "../services/houseService";

import { newHouse } from "../../services/houseService"
import { errorMessage, successMessage } from "../../utils/message";

// export const getHouses = data => {
//     return async dispatch => {
//         const {owns} = data.attributes;
//         owns.map(async quid => {
//             const {data} = await getHouseInformation(quid);
//             await dispatch({type: "GET_HOUSES", payload: data})
//         })
//     }
// }

// export const addNewHouse = house => {
//     return async (dispatch, getState) => {
//         const {data, status} = await newHouse(house);
//         if(status === 200) successMessage("House has been successfully added");
//         await dispatch({type:"ADD_HOUSE", payload: [...getState().houses, data]})
//     }
// }

// export const addNewHouse = house => {
//     return async (dispatch, getState) => {
//         try {
//             const {data, status} = await newHouse(house);
//             if(status === 200) successMessage("House has been successfully added");
//             await dispatch({type:"ADD_HOUSE", payload: [...getState().houses, data]})
//         }
//         catch(ex){
//             errorMessage("Error: Add House has been failed");
//             console.log(ex);
//         }   
//     }
// }

export const addNewHouse = house => {
    return async (dispatch, getState) => {
        await dispatch({type:"ADD_HOUSE", payload: [...getState().houses, house]})
        // await dispatch({type:"FILTER_HOUSES", payload: [...getState().houses, house]})
    }
}

export const addNewFilterHouse = house => {
    return async (dispatch, getState) => {
        await dispatch({type:"ADD_FILTER_HOUSE", payload: [...getState().filterHouses, house]})
        // await dispatch({type:"FILTER_HOUSES", payload: [...getState().houses, house]})
    }
}

export const filterAllHouses = houses => {
    return async (dispatch) => {
        await dispatch({type:"FILTER_HOUSES", payload: houses})
    }
}

export const clearFilteredHouses = () => {
    return async dispatch => {
        await dispatch({type:"CLEAR_FILTER_HOUSES", payload: []});
    }
}

export const clearHouses = () => {
    return async dispatch => {
        await dispatch({type:"CLEAR_HOUSES", payload: []});
    }
}