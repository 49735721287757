import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUser } from '../../redux/actions/user';
import { clearUserInfo } from '../../redux/actions/userInfo';
import { clearFilteredHouses, clearHouses } from '../../redux/actions/houses';
import { clearHouse } from '../../redux/actions/singleHouse';
import { clearDocuments } from '../../redux/actions/houseDocuments';

const Logout = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("token");
        // localStorage.removeItem("session");
        dispatch(clearUser());
        dispatch(clearUserInfo());
        dispatch(clearHouse());
        dispatch(clearHouses());
        dispatch(clearFilteredHouses());
        dispatch(clearDocuments());
        navigate("/");
    }, [dispatch, navigate]);

    return null;
}

export default Logout;
