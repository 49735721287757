import React, { useRef, useState } from 'react';
import { RingLoader } from 'react-spinners';
import homePage from '../../../data/homePage.json';

const PaymentDialog = ({paymentSubmit, closeModal, selectedPayment, setSelectedPayment, offerta, btnLoading, btnRef}) => {

    return (
        <div className='customModal'> 
            <div className='h-100 vw-100 overflow-auto'>      
                <div className='modal-box'>
                    <div className="row pt-1 pb-3 m-0 mb-4 border-bottom-light-gray">
                        <div className="col-12 p-0 d-flex justify-content-between align-items-center">
                            <h4 className='py-2 my-auto dark-purple bold'>Payment</h4>
                            <i className="fa fa-times fa-lg pointer p-2 pe-0" aria-hidden="true" onClick={closeModal}></i>
                        </div>
                    </div>
                    <div className='row m-0 mb-3'>
                        <div className="col-12 my-auto text-center">
                            <h2 className='purple mb-3 fs-30'>{offerta.subtitle}</h2>
                            <p className='mb-3 fs-18 dark-purple'>{offerta.content}</p>
                        </div>
                    </div>
                    
                    <div className='row m-0 mb-3'>
                        <div className="col-12 my-auto text-center d-flex justify-content-center" style={{gap:'20px'}}>
                            <button ref={btnRef} className={`custom-btn ${selectedPayment === '1' ? 'btn-midnight-fill' : 'btn-light-purple'}`} onClick={() => setSelectedPayment('1')}>Flat</button>
                            <button ref={btnRef} className={`custom-btn ${selectedPayment === '2' ? 'btn-midnight-fill' : 'btn-light-purple'}`} onClick={() => setSelectedPayment('2')}>House</button>
                            <button ref={btnRef} className={`custom-btn ${selectedPayment === '3' ? 'btn-midnight-fill' : 'btn-light-purple'}`} onClick={() => setSelectedPayment('3')}>Villa</button>                           
                        </div>
                    </div>
                    <form onSubmit={paymentSubmit}>
                        <div className="row m-0 mb-4 border-bottom-light-gray">
                            <div className="text-center py-2">
                                <section className=''>
                                    
                                    <div className='row m-0 text-center d-flex flex-column align-items-center'>
                                        {offerta.items.map((item, index) => (
                                            parseInt(selectedPayment) === index+1 && <div key={item.id} className="col-12 col-sm-9 col-lg-9 px-0 py-4 p-sm-4 p-md-3 d-center" >
                                                <div className='p-4 border-purple round-20 w-90 h-100 d-flex flex-column'>
                                                    <h2 className='purple mb-3 text-center fs-40'>{item.title}</h2>
                                                    <a href="mailto: info@board4birds.ch" className='mb-5 text-decoration-none'>
                                                        <h4 className='dark-orange m-0 text-center fs-40'>{item.price}</h4>
                                                    </a>
                                                    <ul className='piani p-0 dark-purple fs-18 list-unstyled mb-4 text-start'>
                                                        {item.subItems.map(subItem => (
                                                        <li key={subItem.id} className='mb-2'>{subItem.content}</li> 
                                                        ))}
                                                    </ul>
                                                    <div className='bg-purple-op p-3 round-10'>
                                                        <h5 className='text-uppercase dark-orange fs-14'>{item.target.title}</h5>
                                                        <p className='mb-3 dark-purple'>{item.target.content}</p>
                                                        <h5 className='text-uppercase dark-orange fs-14'>{item.consigliato.title}</h5>
                                                        <p className='mb-3 dark-purple'>{item.consigliato.content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </section>
                            </div>
                        </div>  
                        <div className="row pt-1">
                            <div className="col-12 d-flex justify-content-end">
                                <button ref={btnRef} className='custom-btn btn-milky-fill' type='submit'>
                                    {btnLoading ? 
                                        <div className='w-100 h-100 d-center'> 
                                            <RingLoader color='#312782' loading={btnLoading} size={25}/> 
                                        </div>  
                                    : 'Payment'}
                                </button>
                            </div>
                        </div>
                    </form>   
                </div>
            </div>
        </div>
    )
}

export default PaymentDialog;