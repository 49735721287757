import { useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Select from 'react-select';

import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet-async';
import RingLoader from 'react-spinners/RingLoader';

import { context } from '../../context/user/context';
import { select_style } from '../../components/ui/SelectStyle';

const Register = () => {

    const registerContext = useContext(context);
    const { email,  setEmail, password, setPassword, confirmPassword, setConfirmPassword, role, setRole, policy, setPolicy,
        loading, isVisible, setVisible, isVisibleConfirm, setVisibleConfirm, registerValidator, rolesOption, handleRegister } = registerContext;

    const validator = registerValidator;
    
    const token = localStorage.getItem('token');
    if(!isEmpty(token)) return <Navigate to='/houses' replace /> ;

    return (
        <> 
            <Helmet>
                <title>QR-House | Register</title>
            </Helmet>
            <form className='form' onSubmit={handleRegister}>
                {/* EMAIL */}
                <label htmlFor='email' className='form-label'>Email</label>
                <input 
                    type='email' 
                    name='email' 
                    id='email' 
                    className='form-input' 
                    placeholder='yourmail@mail.com'
                    value={email}
                    onChange={e => {
                        setEmail(e.target.value);
                        validator.current.showMessageFor('email');
                    }} 
                />
                <div className='input-error-message'>
                    {validator.current.message('email', email, 'required|email')}
                </div>

                {/* PASSWORD */}
                <label htmlFor='password' className='form-label'>Password</label>
                <div className='position-relative'>
                    <input 
                        type={!isVisible ? 'password' : 'text'} 
                        name='password' 
                        id='password' 
                        className='form-input' 
                        placeholder='* * * * * * * * * * *'
                        value={password}
                        onChange={e => {
                            setPassword(e.target.value);
                            validator.current.showMessageFor('password');
                        }}
                    />
                    <span className='show-password' onClick={() => setVisible(!isVisible)}>
                        {!isVisible ? <i className='fa fa-eye dark' aria-hidden='true'></i> : <i className='fa fa-eye-slash dark' aria-hidden='true'></i> }
                    </span>
                </div>
                <div className='input-error-message'>
                    {validator.current.message('password', password, 'required|min:3')}
                </div>

                {/* CONFIRM PASSWORD */} 
                <label htmlFor='confirm' className='form-label'>Confirm Password</label>
                <div className='position-relative'>
                    <input 
                        type={!isVisibleConfirm ? 'password' : 'text'} 
                        name='confirm password' 
                        id='confirm' 
                        className='form-input' 
                        placeholder='* * * * * * * * * * *'
                        value={confirmPassword}
                        onChange={e => {
                            setConfirmPassword(e.target.value);
                            validator.current.showMessageFor('confirm');
                        }}
                    />
                    <span className='show-password' onClick={() => setVisibleConfirm(!isVisibleConfirm)}>
                        {!isVisibleConfirm ? <i className='fa fa-eye dark' aria-hidden='true'></i> : <i className='fa fa-eye-slash dark' aria-hidden='true'></i> }
                    </span>
                </div>
                <div className='input-error-message'>
                    {validator.current.message('confirm password', confirmPassword, 'required')}
                    {password === confirmPassword ? null :
                        <div style={{color:'#ef5350'}}>Confirm password is not matched</div>
                    }
                </div>
                
                {/* SELECT ROLE */}
                <label htmlFor='role-select' className='form-label'>Select a role</label>
                <Select 
                    id='role-select' 
                    name='role' 
                    isClearable  
                    options={rolesOption} 
                    styles={select_style}
                    value={{value: role, label: role}}
                    onChange={e => {
                        const role_value = e !== null ? setRole(e.value) : setRole('')
                        validator.current.showMessageFor('role');
                        return role_value;
                    }}
                />
                <div className='input-error-message'>
                    {validator.current.message('role', role, 'required')}
                </div>
                
                {/* POLICY CHECKBOX */}
                <div style={{marginTop:'40px'}}> 
                    <label className='checkbox-label'>
                        <input 
                            type='checkbox' 
                            id='policy' 
                            name='policy' 
                            className='form-checkbox' 
                            // value={policy}
                            checked={policy}
                            onChange={e => {
                                setPolicy(e.currentTarget.checked);
                                validator.current.showMessageFor('policy');
                            }}
                        />
                        I accept the{' '}
                        <Link to='/terms-conditions' className='form-link'>Terms of Use</Link> {` and `}
                        <Link to='/privacy-policy' className='form-link'>Privacy Policy.</Link>
                    </label>
                    <div className='input-error-message'>
                        {validator.current.message('policy', policy, 'required|accepted')}
                    </div>
                </div>
                
                {/* SUBMIT BUTTON */}
                <button type='submit' className='form-btn' disabled={loading}>
                    {loading ?
                    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                        <RingLoader color='#312782' loading={loading} size={30} />
                    </div>: <>Register Now</>}
                </button>
            </form>

            <p className='fs-18'>Not sure how to create the account? watch our video tutorial from 
                <a href="https://www.youtube.com/embed/Ef73LZzALgs" className='milky text-decoration-none' target='_blank'> this link </a> 
            </p>
        </>
    );
}
 
export default Register;