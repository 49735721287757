import { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Select from 'react-select';
import { useTranslation, Trans } from 'react-i18next';
import { isEmpty } from 'lodash';

import NavLinks from '../../data/navLinks.json';
import { select_style_lang  } from './SelectStyle';

const languages = [
    {
        value: 'en', 
        label:  <div className='d-flex justify-content-around align-items-center'>
                    <span>EN</span> 
                    <img src='/images/icons/flags/en.png' width='20px'/> 
                </div>
    },
    {
        value: 'it', 
        label:  <div className='d-flex justify-content-around align-items-center'>
                    <span>IT</span> 
                    <img src='/images/icons/flags/it.png' width='20px'/> 
                </div>
    }
];

const Navbar = ({user, shadow}) => {
    
    const location = useLocation();
    const { i18n } = useTranslation();

    const [menuOpen, setMenuOpen] = useState(false);
    const { navLinks } = NavLinks;

    return (
        <div className={`position-fixed w-100 bg-purple zIndex-1 ${shadow && 'b-shadow-nav bg-white'}`}>
            <nav className='row white pt-4 pt-lg-0 max-container mx-auto h-nav-min' >
                <div className='col-6 col-lg-2 col-xl-3 d-flex align-items-center px-sm-4'>
                    <HashLink to='/#'> <img src={`/images/qr-house-${shadow ? 'purple' :'white'}@2x.png`} alt='QR-House logo' className='w-100 w-logo-max' /> </HashLink>
                </div>
                <div className='col-6 col-lg-10 col-xl-9 d-flex justify-content-end align-items-center ms-auto ps-auto px-sm-4'>
                    <ul className='list-unstyled p-0 m-0 me-4 d-lg-flex flex-wrap justify-content-evenly d-none flex-grow-1'>
                        {navLinks.map(link => (
                            <li key={link.id} className='nav-item bold py-3 px-2'>
                                <HashLink className={`text-decoration-none ${location.hash === `#${link.name}` ? 'milky bold' : `${shadow ? 'purple' :'white'}`}`} to={`/#${link.name}`}><Trans i18nKey={`nav.${link.id}.text`}></Trans></HashLink>
                            </li>
                        ))}
                    </ul>
                    <div className='d-flex d-md-none justify-content-end align-items-center'>
                        <div className='me-3'>
                            <Select 
                                id='language' 
                                name='language' 
                                options={languages} 
                                isSearchable={false}
                                defaultValue={{ label:  
                                                    <div className='d-flex justify-content-around align-items-center'>
                                                        <span>{localStorage.getItem('i18nextLng').substring(0, 2).toUpperCase()}</span> 
                                                        <img src={`/images/icons/flags/${localStorage.getItem('i18nextLng').substring(0, 2)}.png`} width='20px'/> 
                                                    </div>, 
                                                value: localStorage.getItem('i18nextLng') 
                                            }} 
                                styles={select_style_lang}
                                onChange={e => i18n.changeLanguage(e.value)}
                            />
                        </div>
                        <i className={`fa fa-2x ${shadow ? 'purple': 'white'} ${menuOpen ? 'fa-times mb-2' : 'fa-bars'}`} aria-hidden='true' onClick={() => setMenuOpen(!menuOpen)}></i>
                    </div>
                    <div className='d-none d-md-block me-2'>
                        <Select 
                            id='language' 
                            name='language' 
                            options={languages} 
                            isSearchable={false}
                            defaultValue={{ label:  
                                                <div className='d-flex justify-content-around align-items-center'>
                                                    <span>{localStorage.getItem('i18nextLng').substring(0, 2).toUpperCase()}</span> 
                                                    <img src={`/images/icons/flags/${localStorage.getItem('i18nextLng').substring(0, 2)}.png`} width='20px'/> 
                                                </div>, 
                                            value: localStorage.getItem('i18nextLng') 
                                        }} 
                            styles={select_style_lang}
                            onChange={e => i18n.changeLanguage(e.value)}
                        />
                    </div>
                    <div className='d-none d-md-block'>
                        {isEmpty(user) ? <Link to='/login'><button className='custom-btn btn-milky ms-1'>Login</button></Link> : <Link to='/houses'><button className='custom-btn btn-milky ms-1'>Dashboard</button> </Link>}
                    </div>
                </div> 

                <div className='col-12 d-none d-md-flex d-lg-none ms-auto mt-3'>
                    <ul className='list-unstyled p-0 m-auto d-flex overflow-auto'>                
                        {navLinks.map(link => (
                            <li key={link.id} className='nav-item bold p-3'>
                                <HashLink className={`text-decoration-none ${location.hash === `#${link.name}` ? 'milky bold' : `${shadow ? 'purple' :'white'}`}`} to={`/#${link.name}`}>{link.title}</HashLink>
                            </li>
                        ))}
                    </ul>
                </div> 

                <div className='col-12 d-block d-md-none'>
                    <ul className={`list-unstyled p-0 transition ${menuOpen ? 'mh-500 border-top-light-gray mt-3' : 'mh-0'}`}>                
                        {navLinks.map(link => (
                            <li onClick={() => setMenuOpen(false)} key={link.id} className='nav-item bold'>
                                <HashLink className={`d-block p-2 text-decoration-none ${location.hash === `#${link.name}` ? 'milky bold' : `${shadow ? 'purple' :'white'}`}`} to={`/#${link.name}`}>{link.title}</HashLink>
                            </li>
                        ))}
                        <div className='text-center mt-2'>
                            {isEmpty(user) ? <Link to='/login'><button className='custom-btn btn-milky ms-1'>Login</button></Link> : <Link to='/houses'><button className='custom-btn btn-milky ms-1'>Dashboard</button> </Link>}
                        </div>
                    </ul>
                </div>                
            </nav>
        </div>
    )
}

export default Navbar;