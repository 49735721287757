import { useState } from 'react'
import { HashLink } from 'react-router-hash-link';

const ScrollButton = ({route}) => {

    const [isScroll, setScroll] = useState('')
    window.addEventListener('scroll', () => window.scrollY > 0 ? setScroll(true) : setScroll(false));

    return (
        <>
            {window.pageYOffset && 
                <div className='scroll-up' onClick={() => window.scrollTo(0, 0)}>
                    <HashLink className='btn-orange d-center transition circle text-decoration-none w-100 h-100' to={route} >
                        <i className='fa fa-arrow-up' aria-hidden='true'></i>
                    </HashLink>
                </div>
            }
        </>
    )
}

export default ScrollButton