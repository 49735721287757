import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {Link, useSearchParams} from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { isEmpty } from 'lodash';

import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import { getPaymentStatus } from '../../services/houseService';
import { RingLoader } from 'react-spinners';
import { useSelector } from 'react-redux';

const Success = () => {

    const user = useSelector(state => state.user);

    const [searchParams] = useSearchParams();
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentId, setPaymentId] = useState('');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getPaymentStatus(searchParams.get('session_id')).then(response => {
            setPaymentStatus(response.data.status);
            setPaymentId(response.data.payment_id);
            // console.log(response);
            setLoading(false)
        }).catch(ex => {
            // console.log({ex});
            setLoading(false);
        });
    },[]);

    const navigate = useNavigate();
    if(isEmpty(user)) return navigate("/login");
    
    return (
        <div className='main-layout'>
            <Helmet> <title>QR-House | Cancel Payment</title> </Helmet>
            <Header/>
            <main className='mainLayout-main d-center'>
                {loading ?
                    <div className='w-100 h-100 d-center py-3'> 
                        <RingLoader color='#312782' loading={loading} size={80}/> 
                    </div>
                :
                    <div className='max-container text-center p-2 p-md-5' >    
                        <img src={paymentStatus === 'ok' ? "/images/payment-success.png" : '/images/payment-cancelled.png'} alt="alert-image" className='w-100' style={{maxWidth:'344px'}} />
                        <h3 className='mt-5 purple bold text-uppercase'>{paymentStatus === 'ok' ? 'SUCCESS' : 'Sorry'}</h3>
                        <p className='dark-purple'>{paymentStatus === 'ok' ? 'Payment has been successful' : 'Something is wrong!'}</p>
                        <Link to={paymentStatus === 'ok' ? `/houses/add-house/${paymentId}` : '/houses'} className="form-link"> <button className={`custom-btn btn-milky-fill`}>Next</button> </Link>
                    </div>
                }
            </main>
            <Footer/>
        </div> 
    )
}

export default Success;