// import { getHouseDocument } from "../services/houseService";

// export const getHouseDoc = docId => {
//     return async dispatch => {
//         const {data} = await getHouseDocument(docId);
//         await dispatch({type: "GET_HOUSE", payload: data})
//     }
// }

export const addNewDocs = docs => {
    return async (dispatch, getState) => {
        await dispatch({type:"ADD_DOCUMENTS", payload: [...getState().documents, docs]})
    }
}

export const filterAllDocuments = houses => {
    return async (dispatch) => {
        await dispatch({type:"FILTER_DOCUMENTS", payload: houses})
    }
}

export const clearFilteredDocuments = () => {
    return async dispatch => {
        await dispatch({type:"CLEAR_FILTER_DOCUMENTS", payload: []});
    }
}

export const clearDocuments = () => {
    return async dispatch => {
        await dispatch({type:"CLEAR_DOCUMENTS", payload: []});
    }
}