import YouTubeVideo from '../../components/ui/YouTubeVideo';

const Tutorial = () => {
    return (
        <>
            <h4 className='dark-purple bold mb-5'>Tutorial</h4> 
            <div className='grid-container'>
                <div className="grid-item rounded"> <YouTubeVideo src="https://www.youtube.com/embed/Ef73LZzALgs"/> </div>
                <div className="grid-item rounded"> <YouTubeVideo src="https://www.youtube.com/embed/7T56E1hQmNU"/> </div>
                <div className="grid-item rounded"> <YouTubeVideo src="https://www.youtube.com/embed/IfTj8KJHxb8"/> </div>
            </div>
        </>
    )
}

export default Tutorial;