export const filterHouseDocumentsReducer = (state = [], action) => {
    switch(action.type) {
        case "GET_DOCUMENTS_FILTER":
            return [...action.payload];

        case "FILTER_DOCUMENTS":
            return [...action.payload];

        case "CLEAR_FILTER_DOCUMENTS":
            return [...action.payload];

        default:
            return state;
    }
}